import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WelcomeModalComponent } from './welcome-modal.component';
import { IonicModule } from '@ionic/angular';
import { MainButtonModule } from '../main-button/main-button.module';
import { ModalModule } from '../modal/modal.module';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
    declarations: [WelcomeModalComponent],
    exports: [
        WelcomeModalComponent
    ],
    imports: [
        CommonModule,
        TranslateModule,
        IonicModule,
        MainButtonModule,
        ModalModule,
    ]
})
export class WelcomeModalModule { }
