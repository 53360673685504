import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AreaOfInterest } from '@qhot-mx/qhot-database-dto-interfaces';
import { StatusCodes } from 'http-status-codes';
import { MASTER_SERVER_ENDPOINTS } from '../utils/constants';
import { StorageService } from './storage.service';

const serverBaseUrl = MASTER_SERVER_ENDPOINTS.serverBaseUrl;
const baseApiPath = MASTER_SERVER_ENDPOINTS.basePath;

@Injectable({
  providedIn: 'root'
})
export class AreaOfInterestService {

  constructor(
    private http: HttpClient,
    private storageService: StorageService,
  ) { }

  public async getAreaOfInterestCatalog(): Promise<AreaOfInterest[]> {
    const endpoint = MASTER_SERVER_ENDPOINTS.areaOfInterest.catalog;
    const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}`;

    /* eslint-disable @typescript-eslint/naming-convention */
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        Authorization: `Bearer ${await this.storageService.getJwt()}`,
      }),
    };
    /* eslint-enable @typescript-eslint/naming-convention */

    try {
      console.log(`[areaOfInterestService][getCatalog] Querying to "${queryUrl}"...`);
      console.log('[areaOfInterestService][getCatalog] Http Options:', httpOptions);
      return this.http.get<AreaOfInterest[]>(queryUrl, httpOptions).toPromise();
    } catch (e) {
      console.error('[areaOfInterestService][getCatalog] Error while fetching areas of interest', e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }

  public async setUserAreasOfInterest(areasOfInterestToSave: AreaOfInterest[]): Promise<void> {
    const endpoint = MASTER_SERVER_ENDPOINTS.register.setAreasOfInterestEndpoint;
    const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}`;

    const httpOptions = {
      headers: new HttpHeaders({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type':  'application/json',
        // eslint-disable-next-line @typescript-eslint/naming-convention, max-len
        Authorization: `Bearer ${await this.storageService.getJwt()}`,
      }),
    };
    const body = JSON.stringify({
      areasOfInterest: areasOfInterestToSave,
    });

    try {
      console.log(`[areaOfInterestService][setAreasOfInterest] Querying to ${queryUrl}... with options`, httpOptions);
      const response = await this.http.post(queryUrl, body, httpOptions).toPromise() as any;
      console.log(`[areaOfInterestService][setAreasOfInterest] Response from server`, response);
    } catch (e) {
      if ((e as HttpErrorResponse).status === StatusCodes.NOT_FOUND) {
        throw new Error('Usuario inexistente');
      } else {
        console.error('[areaOfInterestService][setAreasOfInterest] Error while setting areas of interest for user', e);
        throw new Error((e as HttpErrorResponse).message);
      }
    }
  }
}
