import { Injectable, OnInit } from '@angular/core';
import { StorageService } from 'src/app/services/storage.service';

@Injectable({
  providedIn: 'root'
})
export class ThemeService implements OnInit {
  themeIsDark = true;

  constructor(
    private storageService: StorageService,
  ) {
  }

  async ngOnInit() {
  }

  setPreferenceTheme() {
    // this.themeIsDark = flag === 'dark';
    if (this.themeIsDark) {
      document.body.setAttribute('color-theme', 'dark');
    } else {
      document.body.setAttribute('color-theme', 'dark');
      // document.body.setAttribute('color-theme', 'light');
    }
    // this.saveThemePreference();
  }

  // private saveThemePreference() {
  //   localStorage.setItem('colorTheme', 'dark');
  // }
}
