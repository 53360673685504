import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { CreatorService } from 'src/app/services/creator.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-welcome-modal',
  templateUrl: './welcome-modal.component.html',
  styleUrls: ['./welcome-modal.component.scss'],
})
export class WelcomeModalComponent implements OnInit {
  @Input() username: string;
  @Input() show: boolean;
  @Input() stripeUrl: string;
  jwt;
  constructor(private storageService: StorageService, private creatorService: CreatorService, private router: Router) { }

  async ngOnInit() {
    this.creatorService.updateToken().then(async (res: any) => {
      console.log(res);
      await this.storageService.setJwt(res.jwt);
    });
    console.log('jwt updated');
  }



  goToEditProfile() {
    this.router.navigateByUrl('/profile');
  }

}
