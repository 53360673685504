import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MASTER_SERVER_ENDPOINTS } from '../utils/constants';
import { StorageService } from './storage.service';
import { UserProfile } from '../pages/profile/profile.page';

const serverBaseUrl = MASTER_SERVER_ENDPOINTS.serverBaseUrl;
const baseApiPath = MASTER_SERVER_ENDPOINTS.basePath;

@Injectable({
  providedIn: 'root'
})
export class CreatorSearchService {
  constructor(
    private http: HttpClient,
    private storageService: StorageService,
  ) { }

  public async getCreatorPublications<T>(creatorId: number, publicationType: 'POSTS' | 'ANNOUNCEMENTS' | 'VIDEOS' |'LIVES'| 'FILES', lastId?) {
    const endpoint = MASTER_SERVER_ENDPOINTS.creator[publicationType.toLowerCase()];
    const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}`;

    /* eslint-disable @typescript-eslint/naming-convention */
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        Authorization: `Bearer ${await this.storageService.getJwt()}`,
      }),
      params: new HttpParams()
        .append('creatorId', creatorId)
        .append('lastPublicationId', lastId ? lastId : null),
    };
    /* eslint-enable @typescript-eslint/naming-convention */

    try {
      console.log(`[creator-search-service][getCreator ${publicationType}] Querying to "${queryUrl}"...`);
      const creatorPosts = await this.http.get<T[]>(queryUrl, httpOptions).toPromise();
      return creatorPosts;
    } catch (e) {
      console.error(`[creator-search-service][getCreator ${publicationType}] Error while getting posts`, e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }

  public async searchCreator(searchTerm: string): Promise<UserProfile[]> {
    const endpoint = MASTER_SERVER_ENDPOINTS.creator.search;
    const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}`;

    /* eslint-disable @typescript-eslint/naming-convention */
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        Authorization: `Bearer ${await this.storageService.getJwt()}`,
      }),
      params: new HttpParams()
        .append('searchValue', searchTerm),
    };
    /* eslint-enable @typescript-eslint/naming-convention */

    try {
      console.log(`[creator-search-service][searchCreator] Querying to "${queryUrl}"...`);
      const userProfiles = this.http.get<UserProfile[]>(queryUrl, httpOptions).toPromise();
      return userProfiles;
    } catch (e) {
      console.error('[creator-search-service][searchCreator] Error while searching users', e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }

    public async getSearchByCategory(category, pageSize = 5, lastUserId = null): Promise<UserProfile[]> {
    const endpoint = MASTER_SERVER_ENDPOINTS.creator.searchByCategory;
    const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}`;

    /* eslint-disable @typescript-eslint/naming-convention */
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        Authorization: `Bearer ${await this.storageService.getJwt()}`,
      }),
      params: new HttpParams()
        .append('areaOfInterestId', category)
        .append('pageSize', pageSize)
        .append('lastUserId', lastUserId)
    };
    /* eslint-enable @typescript-eslint/naming-convention */

    try {
      console.log(`[creator-search-service][getSearchByCategory] Querying to "${queryUrl}"...`);
      const userProfiles = this.http.get<UserProfile[]>(queryUrl, httpOptions).toPromise();
      return userProfiles;
    } catch (e) {
      console.error('[creator-search-service][getSearchByCategory] Error while searching users', e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }

  public async getPopularCreators(): Promise<UserProfile[]> {
    const endpoint = MASTER_SERVER_ENDPOINTS.creator.popular;
    const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}`;

    /* eslint-disable @typescript-eslint/naming-convention */
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        Authorization: `Bearer ${await this.storageService.getJwt()}`,
      })
    };
    /* eslint-enable @typescript-eslint/naming-convention */

    try {
      console.log(`[creator-search-service][getPopularCreators] Querying to "${queryUrl}"...`);
      const userProfiles = this.http.get<UserProfile[]>(queryUrl, httpOptions).toPromise();
      return userProfiles;
    } catch (e) {
      console.error('[creator-search-service][getPopularCreators] Error while searching users', e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }

  public async getRecentCreators(pageSize: number , lastUserId: any = null): Promise<UserProfile[]> {
    const endpoint = MASTER_SERVER_ENDPOINTS.creator.recent;
    const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}`;

    /* eslint-disable @typescript-eslint/naming-convention */
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        Authorization: `Bearer ${await this.storageService.getJwt()}`,
      }),
      params: new HttpParams()
        .append('pageSize', pageSize)
        .append('lastUserId', lastUserId)
    };

    try {
      console.log(`[creator-search-service][getRecentCreators] Querying to "${queryUrl}"...`);
      const userProfiles = this.http.get<UserProfile[]>(queryUrl, httpOptions).toPromise();
      return userProfiles;
    } catch (e) {
      console.error('[creator-search-service][getRecentCreators] Error while searching users', e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }
}
