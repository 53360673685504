import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Publication } from '@blackfor-mexico/qvery-dto-interfaces';
import { Observable, throwError } from 'rxjs';
import { FeedAnnouncement, FeedPublication, UserFeedResponse, UserLiveVideosFeedResponse } from '../pages/feed/feed.page';
import { MASTER_SERVER_ENDPOINTS } from '../utils/constants';
import { StorageService } from './storage.service';
import { catchError } from 'rxjs/operators';

const serverBaseUrl = MASTER_SERVER_ENDPOINTS.serverBaseUrl;
const baseApiPath = MASTER_SERVER_ENDPOINTS.basePath;

export const isFeedPublication = (
  feedResponse: UserFeedResponse,
): feedResponse is FeedPublication => {
  const publicationTypeName = feedResponse?.publicationType.name;
  return publicationTypeName === 'POST';
};

export const isEndedLiveVideoPublication = (
  feedResponse: UserFeedResponse,
): feedResponse is FeedPublication => {
  const publicationTypeName = feedResponse?.publicationType.name;
  return publicationTypeName === 'FINISHED_LIVE_VIDEO';
};

export const isFeedAnnouncement = (
  feedResponse: UserFeedResponse,
): feedResponse is FeedAnnouncement => {
  const publicationTypeName = feedResponse?.publicationType.name;
  return publicationTypeName === 'ANNOUNCEMENT';
};

@Injectable({
  providedIn: 'root'
})
export class PublicationService {
  typesNotShowing = ['application/pdf',
  'text/csv', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'image/vnd.adobe.photoshop', 'application/x-photoshop', 'application/photoshop', 'application/psd',
  'image/psd', 'image/photoshop', 'image/x-photoshop',
  'application/msword', 'application/vnd.ms-word.document.macroEnabled.12',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/coreldraw', 'application/cdr', 'application/vnd.corel-draw', 'application/x-coreldraw', 'application/x-cdr',
  'image/x-cdr', 'image/CDR', 'image/x-dcraw', 'image/cdr', 'image/x-coreldraw',
  'application/illustrator',
  'application/postscript', 'application/octet-stream', ''];
  constructor(
    private http: HttpClient,
    private storageService: StorageService,
  ) { }

  public async getUserFeed(lastPublicationId?): Promise<UserFeedResponse[]> {
    const endpoint = MASTER_SERVER_ENDPOINTS.publication.feed;
    const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}`;

    /* eslint-disable @typescript-eslint/naming-convention */
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.storageService.getJwt()}`,
      }),
      params: new HttpParams()
        .append('lastPublicationId', lastPublicationId ? lastPublicationId : null),
    };
    /* eslint-enable @typescript-eslint/naming-convention */

    try {
      console.log(`[getUserFeed] Querying to "${queryUrl}"...`);
      const feedPublications = await this.http.get<UserFeedResponse[]>(queryUrl, httpOptions).toPromise();
      return feedPublications;
    } catch (e) {
      console.error(`[getUserFeed] Error while getting feed posts`, e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }

  /**** Livestream endpoints ****/
  public async getLiveVideosFeed(lastPublicationId?) {
    const endpoint = MASTER_SERVER_ENDPOINTS.livestream.getActiveLivestream;
    const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}`;

    /* eslint-disable @typescript-eslint/naming-convention */
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.storageService.getJwt()}`,
      }),
      params: new HttpParams()
        .append('lastPublicationId', lastPublicationId ? lastPublicationId : null),
    };
    /* eslint-enable @typescript-eslint/naming-convention */

    try {
      console.log(`[getLiveVideosFeed] Querying to "${queryUrl}"...`);
      const feedLiveVideos = await this.http.get<UserLiveVideosFeedResponse[]>(queryUrl, httpOptions).toPromise();
      return feedLiveVideos;
    } catch (e) {
      console.error(`[getLiveVideosFeed] Error while getting live videos feed`, e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }

  public async getLivestreamById(creatorLivestreamId?) {
    const endpoint = MASTER_SERVER_ENDPOINTS.livestream.getLivestreamById;
    const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}`;

    /* eslint-disable @typescript-eslint/naming-convention */
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.storageService.getJwt()}`,
      }),
      params: new HttpParams()
        .append('creatorLivestreamId', creatorLivestreamId),
    };
    /* eslint-enable @typescript-eslint/naming-convention */

    try {
      console.log(`[getLiveVideosFeed] Querying to "${queryUrl}"...`);
      const feedLiveVideos = await this.http.get<UserLiveVideosFeedResponse[]>(queryUrl, httpOptions).toPromise();
      return feedLiveVideos;
    } catch (e) {
      console.error(`[getLiveVideosFeed] Error while getting live videos feed`, e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }

  public async getLivestreamInfo(arn?) {
    const endpoint = MASTER_SERVER_ENDPOINTS.livestream.getLivestreamInfo;
    const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}`;

    /* eslint-disable @typescript-eslint/naming-convention */
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.storageService.getJwt()}`,
      }),
      params: new HttpParams()
        .append('arn', arn.arn),
    };
    /* eslint-enable @typescript-eslint/naming-convention */

    try {
      console.log(`[getLiveVideosFeed] Querying to "${queryUrl}"...`);
      const feedLiveVideos = await this.http.get<UserLiveVideosFeedResponse[]>(queryUrl, httpOptions).toPromise();
      return feedLiveVideos;
    } catch (e) {
      console.error(`[getLiveVideosFeed] Error while getting live videos feed`, e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }

  public async getLivestreamInfoByCreatorId() {
    const endpoint = MASTER_SERVER_ENDPOINTS.livestream.getLivestreamInfoByCreatorId;
    const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}`;

    /* eslint-disable @typescript-eslint/naming-convention */
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.storageService.getJwt()}`,
      }),
    };
    /* eslint-enable @typescript-eslint/naming-convention */

    try {
      console.log(`[getLiveVideosFeed] Querying to "${queryUrl}"...`);
      const feedLiveVideos = await this.http.get<UserLiveVideosFeedResponse[]>(queryUrl, httpOptions).toPromise();
      return feedLiveVideos;
    } catch (e) {
      console.error(`[getLiveVideosFeed] Error while getting live videos feed`, e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }

  public async updateLivestreamStatus(creatorLivestream?) {
    const endpoint = MASTER_SERVER_ENDPOINTS.livestream.updateLivestreamStatus;
    const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}`;

    /* eslint-disable @typescript-eslint/naming-convention */
    const httpOptions = {
      headers: new HttpHeaders({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.storageService.getJwt()}`,
      }),
      observe: 'response' as const,
    };

    const body = JSON.stringify(creatorLivestream);
    try {
      console.log(`[publication-service][updatePublication] Querying to ${queryUrl}... with body ${body}`);
      const response = await this.http.put(queryUrl, body, httpOptions).toPromise();
      console.log(`[publication-service][updatePublication] Response from server: ${response.status} ${response.statusText}`);
      return response;
    } catch (e) {
      console.error('[publication-service][updatePublication] Error while creating ad', e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }

  public async createLivestreamHall(form) {
    const endpoint = MASTER_SERVER_ENDPOINTS.livestream.createLivestreamHall;
    const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}`;

    /* eslint-disable @typescript-eslint/naming-convention */
    const httpOptions = {
      headers: new HttpHeaders({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.storageService.getJwt()}`,
      }),
      observe: 'response' as const,
    };

    const body = JSON.stringify(form);
    try {
      console.log(`[publication-service][newPublication] Querying to ${queryUrl}... with body ${body}`);
      const response = await this.http.post(queryUrl, body, httpOptions).toPromise();
      console.log(`[publication-service][newPublication] Response from server: ${response.status} ${response.statusText}`);
      return response;
    } catch (e) {
      console.error('[publication-service][newPublication] Error while creating ad', e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }

  public async createLivestreamHallFan(form) {
    const endpoint = MASTER_SERVER_ENDPOINTS.livestream.createLivestreamHallFan;
    const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}`;

    /* eslint-disable @typescript-eslint/naming-convention */
    const httpOptions = {
      headers: new HttpHeaders({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.storageService.getJwt()}`,
      }),
      observe: 'response' as const,
    };

    const body = JSON.stringify(form);
    try {
      console.log(`[publication-service][newPublication] Querying to ${queryUrl}... with body ${body}`);
      const response = await this.http.post(queryUrl, body, httpOptions).toPromise();
      console.log(`[publication-service][newPublication] Response from server: ${response.status} ${response.statusText}`);
      return response;
    } catch (e) {
      console.error('[publication-service][newPublication] Error while creating ad', e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }

  public async endedLivestreamHall(creatorLivestreamHall?) {
    const endpoint = MASTER_SERVER_ENDPOINTS.livestream.endedLivestreamHall;
    const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}`;

    /* eslint-disable @typescript-eslint/naming-convention */
    const httpOptions = {
      headers: new HttpHeaders({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.storageService.getJwt()}`,
      }),
      observe: 'response' as const,
    };

    const body = JSON.stringify(creatorLivestreamHall);
    try {
      console.log(`[publication-service][updatePublication] Querying to ${queryUrl}... with body ${body}`);
      const response = await this.http.put(queryUrl, body, httpOptions).toPromise();
      console.log(`[publication-service][updatePublication] Response from server: ${response.status} ${response.statusText}`);
      return response;
    } catch (e) {
      console.error('[publication-service][updatePublication] Error while creating ad', e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }

  public async updateLivestreamHallChatRoomId(creatorLivestreamHall?) {
    const endpoint = MASTER_SERVER_ENDPOINTS.livestream.updateLivestreamHallChatRoomId;
    const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}`;

    /* eslint-disable @typescript-eslint/naming-convention */
    const httpOptions = {
      headers: new HttpHeaders({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.storageService.getJwt()}`,
      }),
      observe: 'response' as const,
    };

    const body = JSON.stringify(creatorLivestreamHall);
    try {
      console.log(`[publication-service][updatePublication] Querying to ${queryUrl}... with body ${body}`);
      const response = await this.http.put(queryUrl, body, httpOptions).toPromise();
      console.log(`[publication-service][updatePublication] Response from server: ${response.status} ${response.statusText}`);
      return response;
    } catch (e) {
      console.error('[publication-service][updatePublication] Error while creating ad', e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }

  public async createTokenChat(creatorLivestreamHallId) {
    const endpoint = MASTER_SERVER_ENDPOINTS.livestream.createTokenChat;
    const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}`;

    /* eslint-disable @typescript-eslint/naming-convention */
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.storageService.getJwt()}`,
      }),
      params: new HttpParams()
        .append('creatorLivestreamHallId', creatorLivestreamHallId),
    };
    /* eslint-enable @typescript-eslint/naming-convention */

    try {
      console.log(`[createTokenChat] Querying to "${queryUrl}"...`);
      const feedLiveVideos = await this.http.get<UserLiveVideosFeedResponse[]>(queryUrl, httpOptions).toPromise();
      return feedLiveVideos;
    } catch (e) {
      console.error(`[createTokenChat] Error while getting live videos feed`, e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }

  public async updateLivestreamHallFanEndedAt(creatorLivestreamHallFanId?) {
    const endpoint = MASTER_SERVER_ENDPOINTS.livestream.updateLivestreamHallFanEndedAt;
    const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}`;

    /* eslint-disable @typescript-eslint/naming-convention */
    const httpOptions = {
      headers: new HttpHeaders({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.storageService.getJwt()}`,
      }),
      observe: 'response' as const,
    };

    const body = JSON.stringify(creatorLivestreamHallFanId);
    try {
      console.log(`[publication-service][updateLivestreamHallFanEndedAt] Querying to ${queryUrl}... with body ${body}`);
      const response = await this.http.put(queryUrl, body, httpOptions).toPromise();
      console.log(`[publication-service][updateLivestreamHallFanEndedAt] Response from server: ${response.status} ${response.statusText}`);
      return response;
    } catch (e) {
      console.error('[publication-service][updateLivestreamHallFanEndedAt] Error while creating ad', e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }

  public async newPublication(form) {
    const endpoint = MASTER_SERVER_ENDPOINTS.publication.create;
    const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}`;

    /* eslint-disable @typescript-eslint/naming-convention */
    const httpOptions = {
      headers: new HttpHeaders({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.storageService.getJwt()}`,
      }),
      observe: 'response' as const,
    };

    const body = JSON.stringify(form);
    try {
      console.log(`[publication-service][newPublication] Querying to ${queryUrl}... with body ${body}`);
      const response = await this.http.post(queryUrl, body, httpOptions).toPromise();
      console.log(`[publication-service][newPublication] Response from server: ${response.status} ${response.statusText}`);
      return response;
    } catch (e) {
      console.error('[publication-service][newPublication] Error while creating ad', e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }

  public async updatePublication(form) {
    const endpoint = MASTER_SERVER_ENDPOINTS.publication.update;
    const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}`;

    /* eslint-disable @typescript-eslint/naming-convention */
    const httpOptions = {
      headers: new HttpHeaders({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.storageService.getJwt()}`,
      }),
      observe: 'response' as const,
    };

    const body = JSON.stringify(form);
    try {
      console.log(`[publication-service][updatePublication] Querying to ${queryUrl}... with body ${body}`);
      const response = await this.http.put(queryUrl, body, httpOptions).toPromise();
      console.log(`[publication-service][updatePublication] Response from server: ${response.status} ${response.statusText}`);
      return response;
    } catch (e) {
      console.error('[publication-service][updatePublication] Error while creating ad', e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }

  public async deletePublication(id: any) {
    const endpoint = MASTER_SERVER_ENDPOINTS.publication.delete;
    const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}`;

    /* eslint-disable @typescript-eslint/naming-convention */
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.storageService.getJwt()}`,
      }),
      params: new HttpParams()
        .append('publicationId', id),
    };
    /* eslint-enable @typescript-eslint/naming-convention */

    try {
      console.log(`[deletePublication] Querying to "${queryUrl}"...`);
      const deleteResponse = await this.http.delete<any>(queryUrl, httpOptions).toPromise();
      return deleteResponse;
    } catch (e) {
      console.error(`[deletePulication] Error while deleting publication`, e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }

  public uploadMediaPublication(form, requestId, jwt): Observable<any> {
    const endpoint = MASTER_SERVER_ENDPOINTS.publication.media;
    const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}/${requestId}`; // depend on api this is in url or no

    /* eslint-disable @typescript-eslint/naming-convention */
    const httpOptions = {
      headers: new HttpHeaders({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Authorization: `Bearer ${jwt}`,
      }),
      reportProgress: true,
      observe: 'events' as const,
    };
    const body = form;

    console.log(`[publicationService][uploadMediaPublication] Querying to ${queryUrl}... with body ${body}`);
    return this.http.post(queryUrl, body, httpOptions).pipe(catchError(this.errorMgmt));
  }

  public uploadBlurredMediaPublication(form, requestId, jwt): Observable<any> {
    const endpoint = MASTER_SERVER_ENDPOINTS.publication.blurred;
    const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}/${requestId}`; // depend on api this is in url or no

    /* eslint-disable @typescript-eslint/naming-convention */
    const httpOptions = {
      headers: new HttpHeaders({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Authorization: `Bearer ${jwt}`,
      }),
      reportProgress: true,
      observe: 'events' as const,
    };
    const body = form;

    console.log(`[publicationService][uploadMediaPublication] Querying to ${queryUrl}... with body ${body}`);
    return this.http.post(queryUrl, body, httpOptions).pipe(catchError(this.errorMgmt));
  }

  errorMgmt(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => errorMessage);
  }

  public async getPublication(publicationId) {
    const endpoint = MASTER_SERVER_ENDPOINTS.publication.readPublication;
    const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}`;

    /* eslint-disable @typescript-eslint/naming-convention */
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.storageService.getJwt()}`,
      }),
      params: new HttpParams()
        .append('publicationId', publicationId),
    };

    try {
      console.log(`[getPublication] Querying to "${queryUrl}"...`);
      const response = await this.http.get(queryUrl, httpOptions).toPromise();
      return response;
    } catch (e) {
      console.error(`[getPublication] Error while getting feed posts`, e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }


  public async createPublicationReaction(form) {
    const endpoint = MASTER_SERVER_ENDPOINTS.publication.createReaction;
    const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}`;

    /* eslint-disable @typescript-eslint/naming-convention */
    const httpOptions = {
      headers: new HttpHeaders({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.storageService.getJwt()}`,
      }),
      observe: 'response' as const,
    };

    const body = JSON.stringify(form);
    try {
      console.log(`[publication-service][createPublicationReaction] Querying to ${queryUrl}... with body ${body}`);
      const response = await this.http.post(queryUrl, body, httpOptions).toPromise();
      console.log(`[publication-service][createPublicationReaction] Response from server: ${response.status} ${response.statusText}`);
      return response;
    } catch (e) {
      console.error('[publication-service][createPublicationReaction] Error while creating reaction', e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }

  public async getComments(publicationId, lastCommentId?) {
    const endpoint = MASTER_SERVER_ENDPOINTS.publication.comments;
    const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}`;

    /* eslint-disable @typescript-eslint/naming-convention */
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.storageService.getJwt()}`,
      }),
      params: new HttpParams()
        .append('publicationId', publicationId)
        .append('lastCommentId', lastCommentId ? lastCommentId : null),
    };

    try {
      console.log(`[getComments] Querying to "${queryUrl}"...`);
      const response = await this.http.get(queryUrl, httpOptions).toPromise();
      return response;
    } catch (e) {
      console.error(`[getComments] Error while getting feed posts`, e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }

  public async getStatusLive(arnLivestream) {
    const endpoint = MASTER_SERVER_ENDPOINTS.livestream.getStatusLive;
    const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}`;

    /* eslint-disable @typescript-eslint/naming-convention */
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.storageService.getJwt()}`,
      }),
      params: new HttpParams()
        .append('arn', arnLivestream),
    };
    try {
      console.log(`[getComments] Querying to "${queryUrl}"...`);
      const response = await this.http.get(queryUrl, httpOptions).toPromise();
      return response;
    } catch (e) {
      console.error(`[getComments] Error while getting feed posts`, e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }

  public async getFansOnLivestream(creatorLivestreamHallId) {
    const endpoint = MASTER_SERVER_ENDPOINTS.livestream.getFansOnLivestream;
    const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}`;

    /* eslint-disable @typescript-eslint/naming-convention */
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.storageService.getJwt()}`,
      }),
      params: new HttpParams()
        .append('creatorLivestreamHallId', creatorLivestreamHallId),
    };
    try {
      console.log(`[getComments] Querying to "${queryUrl}"...`);
      const response = await this.http.get(queryUrl, httpOptions).toPromise();
      return response;
    } catch (e) {
      console.error(`[getComments] Error while getting feed posts`, e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }

  public async deletePublicationReaction(form) {
    const endpoint = MASTER_SERVER_ENDPOINTS.publication.deleteReaction;
    const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}`;

    const body = JSON.stringify(form);
    /* eslint-disable @typescript-eslint/naming-convention */
    const httpOptions = {
      headers: new HttpHeaders({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.storageService.getJwt()}`,
      }),
      body
    };

    try {
      const response = await this.http.delete(queryUrl, httpOptions).toPromise();
      return response;
    } catch (e) {
      console.error('[publication-service][deletePublicationReaction] Error while creating reaction', e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }

  public async createPublicationComment(form) {
    const endpoint = MASTER_SERVER_ENDPOINTS.publication.comment;
    const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}`;

    /* eslint-disable @typescript-eslint/naming-convention */
    const httpOptions = {
      headers: new HttpHeaders({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.storageService.getJwt()}`,
      })
    };
    const body = JSON.stringify(form);

    try {
      const response = await this.http.post(queryUrl, body, httpOptions).toPromise();
      return response;
    } catch (e) {
      console.error('[publication-service][createPublicationComment] Error while creating comment', e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }

  public async deletePublicationComment(form) {
    const endpoint = MASTER_SERVER_ENDPOINTS.publication.comment;
    const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}`;

    const body = JSON.stringify(form);
    /* eslint-disable @typescript-eslint/naming-convention */
    const httpOptions = {
      headers: new HttpHeaders({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.storageService.getJwt()}`,
      }),
      body
    };

    try {
      const response = await this.http.delete(queryUrl, httpOptions).toPromise();
      return response;
    } catch (e) {
      console.error('[publication-service][deletePublicationComment] Error while deleting comment', e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }

  public async getReactions(publicationId, lastPublicationReactionId?) {
    const endpoint = MASTER_SERVER_ENDPOINTS.publication.reactions;
    const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}`;

    /* eslint-disable @typescript-eslint/naming-convention */
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.storageService.getJwt()}`,
      }),
      params: new HttpParams()
        .append('publicationId', publicationId)
        .append('lastPublicationReactionId', lastPublicationReactionId ? lastPublicationReactionId : null),
    };

    try {
      console.log(`[getReactions] Querying to "${queryUrl}"...`);
      const response = await this.http.get(queryUrl, httpOptions).toPromise();
      return response;
    } catch (e) {
      console.error(`[getReactions] Error while getting feed posts`, e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }

  // TODO: Fix this when endpoint will be ready
  public async reportPublication(body) {
    const endpoint = MASTER_SERVER_ENDPOINTS.publication.reportPublication;
    const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}`;

    /* eslint-disable @typescript-eslint/naming-convention */
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.storageService.getJwt()}`,
      })
    };
    /* eslint-enable @typescript-eslint/naming-convention */

    try {
      console.log(`[reportPublication] Querying to "${queryUrl}"...`);
      const reportResponse = await this.http.post(queryUrl, body, httpOptions).toPromise();
      return reportResponse;
    } catch (e) {
      console.error(`[reportPublication] Error while reporting publication`, e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }

  /* Methods that are used in different views and components */
  public publicationHasImage(feedResponse: UserFeedResponse): boolean {
    if (isFeedPublication(feedResponse)) {
      const publicationContent = feedResponse.content;
      return publicationContent.type?.includes('image');
    } else if (isFeedAnnouncement(feedResponse)) {
      // An announcement only allows images
      return true;
    }
    return false;
  }

  public mediaIsImage(mediaitem?): boolean {
    return mediaitem?.mimeType?.includes('image');
  }
  public mediaIsEndedLivestream(mediaItem?): boolean{
    return mediaItem?.mimeType?.includes('mpegurl');
  }

  public mediaIsVideo(mediaItem?): boolean {
    return mediaItem?.mimeType?.includes('video');
  }

  public mediaIsPdf(mediaItem?): boolean {
    return mediaItem?.mimeType?.includes('pdf');
  }

  // public mediaIsFile(post?): boolean {
  //   return post?.content?.isFileMediaContent;
  // }

  public isMediaNotShowable(mediaItem?): boolean {
    return this.typesNotShowing.includes(mediaItem?.mimeType);
  }

  public mediaIsDownloadable(post?): boolean {
    return post?.content?.isDownloadable;
  }

  public getPublicationThumbnail(feedResponse: UserFeedResponse): string {
    if (isFeedPublication(feedResponse)) {
      return feedResponse.content.media[0];
    } else if (isFeedAnnouncement(feedResponse)) {
      return feedResponse.content.image;
    }
    return '';
  }

  public getPublicationVideo(feedResponse: UserFeedResponse): string {
    if (isFeedPublication(feedResponse)) {
      return feedResponse.content.media[0];
    } else if (isFeedAnnouncement(feedResponse)) {
      return feedResponse.content.image;
    }
    return '';
  }

}
