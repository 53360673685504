import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MASTER_SERVER_ENDPOINTS } from '../utils/constants';
import { StorageService } from './storage.service';

const serverBaseUrl = MASTER_SERVER_ENDPOINTS.serverBaseUrl;
const baseApiPath = MASTER_SERVER_ENDPOINTS.basePath;

@Injectable({
  providedIn: 'root'
})
export class CreatorService {

  constructor(
    private http: HttpClient,
    private storageService: StorageService,
  ) { }

  public async getMyFans(lastId?: number) {
    const endpoint = MASTER_SERVER_ENDPOINTS.creator.myFans;
    const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}`;

    /* eslint-disable @typescript-eslint/naming-convention */
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.storageService.getJwt()}`,
      }),
      params: new HttpParams()
        .append('pageSize', 5).
        append('lastFanId', lastId ? lastId : null),
    };
    /* eslint-enable @typescript-eslint/naming-convention */

    try {
      console.log(`[creator-service][getMyFans] Querying to "${queryUrl}"...`);
      const myFans = await this.http.get(queryUrl, httpOptions).toPromise();
      return myFans;
    } catch (e) {
      console.error('[creator-service][getMyFans] Error while getting fans', e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }

  public async getStatistics(month, year) {
    const endpoint = MASTER_SERVER_ENDPOINTS.creator.statistics;
    const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}`;

    /* eslint-disable @typescript-eslint/naming-convention */
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.storageService.getJwt()}`,
      }),
      params: new HttpParams()
        .append('month', month).
        append('year', year),
    };
    /* eslint-enable @typescript-eslint/naming-convention */

    try {
      console.log(`[creator-service][getStatistics] Querying to "${queryUrl}"...`);
      const myStatistics = await this.http.get(queryUrl, httpOptions).toPromise();
      return myStatistics;
    } catch (e) {
      console.error('[creator-service][getStatistics] Error while getting statistics', e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }

  public async getCommissionPercentage() {
    const endpoint = MASTER_SERVER_ENDPOINTS.creator.commissionPercentage;
    const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}`;

    /* eslint-disable @typescript-eslint/naming-convention */
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.storageService.getJwt()}`,
      })
    };
    /* eslint-enable @typescript-eslint/naming-convention */

    try {
      console.log(`[creator-service][getCommissionPercentage] Querying to "${queryUrl}"...`);
      const myStatistics = await this.http.get(queryUrl, httpOptions).toPromise();
      return myStatistics;
    } catch (e) {
      console.error('[creator-service][getCommissionPercentage] Error while getting statistics', e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }

  public async getPaymentStatistics(month, year, type) {
    const endpoint = MASTER_SERVER_ENDPOINTS.creator.payments;
    const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}`;

    /* eslint-disable @typescript-eslint/naming-convention */
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.storageService.getJwt()}`,
      }),
      params: new HttpParams()
        .append('month', month).
        append('year', year).
        append('paidContentTypeId', type)
    };
    /* eslint-enable @typescript-eslint/naming-convention */

    try {
      console.log(`[creator-service][getPaymentStatistics] Querying to "${queryUrl}"...`);
      const infoPayment = await this.http.get(queryUrl, httpOptions).toPromise();
      return infoPayment;
    } catch (e) {
      console.error('[creator-service][getPaymentStatistics] Error while getting payment statistics', e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }

  public async getCurrentRates() {
    const endpoint = MASTER_SERVER_ENDPOINTS.creator.paidContentRates;
    const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}`;

    /* eslint-disable @typescript-eslint/naming-convention */
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.storageService.getJwt()}`,
      })
    };

    try {
      console.log(`[creator-service][getCurrentRates] Querying to "${queryUrl}"...`);
      const response = await this.http.get(queryUrl, httpOptions).toPromise();
      return response;
    } catch (e) {
      console.error('[creator-service][getCurrentRates] Error while getting rates', e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }

  public async getChangeRatesRequest() {
    const endpoint = MASTER_SERVER_ENDPOINTS.creator.paidContentRatesChange;
    const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}`;

    /* eslint-disable @typescript-eslint/naming-convention */
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.storageService.getJwt()}`,
      })
    };

    try {
      console.log(`[creator-service][getChangeRatesRequest] Querying to "${queryUrl}"...`);
      const response = await this.http.get(queryUrl, httpOptions).toPromise();
      return response;
    } catch (e) {
      console.error('[creator-service][getChangeRatesRequest] Error while getting rates', e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }

  public async newChangeRatesRequest(params) {
    const endpoint = MASTER_SERVER_ENDPOINTS.creator.paidContentRatesChange;
    const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}`;

    /* eslint-disable @typescript-eslint/naming-convention */
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.storageService.getJwt()}`,
      })
    };

    const body = JSON.stringify(params);

    try {
      console.log(`[creator-service][newChangeRatesRequest] Querying to "${queryUrl}"...`);
      const response = await this.http.post(queryUrl, body, httpOptions).toPromise();
      return response;
    } catch (e) {
      console.error('[creator-service][newChangeRatesRequest] Error while creating rates request', e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }

  public async createBlockedUser(params) {
    const endpoint = MASTER_SERVER_ENDPOINTS.creator.blockedUser;
    const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}`;

    /* eslint-disable @typescript-eslint/naming-convention */
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.storageService.getJwt()}`,
      })
    };
    /* eslint-enable @typescript-eslint/naming-convention */
    const body = JSON.stringify(params);

    try {
      console.log(`[creator-service][createBlockedUser] Querying to "${queryUrl}"...`);
      const myFans = await this.http.post(queryUrl, body, httpOptions).toPromise();
      return myFans;
    } catch (e) {
      console.error('[creator-service][createBlockedUser] Error while getting fans', e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }

  public async getBlockedUsers() {
    const endpoint = MASTER_SERVER_ENDPOINTS.creator.blockedUser;
    const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}`;

    /* eslint-disable @typescript-eslint/naming-convention */
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.storageService.getJwt()}`,
      })
    };
    /* eslint-enable @typescript-eslint/naming-convention */

    try {
      console.log(`[creator-service][getMyFans] Querying to "${queryUrl}"...`);
      const myFans = await this.http.get(queryUrl, httpOptions).toPromise();
      return myFans;
    } catch (e) {
      console.error('[creator-service][getMyFans] Error while getting fans', e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }

  public async deleteBlockedUsers(blockedUserId: any) {
    const endpoint = MASTER_SERVER_ENDPOINTS.creator.blockedUser;
    const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}`;

    /* eslint-disable @typescript-eslint/naming-convention */
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.storageService.getJwt()}`,
      }),
      params: new HttpParams()
        .append('blockedUserId', blockedUserId),
    };
    /* eslint-enable @typescript-eslint/naming-convention */

    try {
      console.log(`[deletePublication] Querying to "${queryUrl}"...`);
      const deleteResponse = await this.http.delete<any>(queryUrl, httpOptions).toPromise();
      return deleteResponse;
    } catch (e) {
      console.error(`[deletePulication] Error while deleting publication`, e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }

  public async donationResponse(params) {
    const endpoint = MASTER_SERVER_ENDPOINTS.creator.donationResponse;
    const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}`;

    /* eslint-disable @typescript-eslint/naming-convention */
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.storageService.getJwt()}`,
      })
    };
    /* eslint-enable @typescript-eslint/naming-convention */
    const body = JSON.stringify(params);

    try {
      console.log(`[creator-service][donationResponse] Querying to "${queryUrl}"...`);
      const myFans = await this.http.post(queryUrl, body, httpOptions).toPromise();
      return myFans;
    } catch (e) {
      console.error('[creator-service][donationResponse] Error while creating donation response', e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }

  public async uploadMediaCoverPicture(form: FormData) {
    const endpoint = MASTER_SERVER_ENDPOINTS.creator.uploadMediaCoverPicture;
    const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}`;

    /* eslint-disable @typescript-eslint/naming-convention */
    const httpOptions = {
      headers: new HttpHeaders({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        // 'Content-Type':  'multipart/form-data',
        Authorization: `Bearer ${await this.storageService.getJwt()}`,
      }),
      observe: 'response' as const,
      params: new HttpParams()
        .append('profileMediaType', 'cover_picture'),
    };
    const body = form;

    try {
      console.log(`[creatorService][uploadMediaCoverPicture] Querying to ${queryUrl}... with body ${body}`);
      const response = await this.http.post(queryUrl, body, httpOptions).toPromise();
      console.log(`[creatorService][uploadMediaCoverPicture] Response from server: ${response.status} ${response.statusText}`);
      return response;
    } catch (e) {
      console.error('[creatorService][uploadMediaCoverPicture] Error while uploading media', e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }

  public async getPricesCatalog() {
    const endpoint = MASTER_SERVER_ENDPOINTS.creator.getPricesCatalog;
    const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}`;

    /* eslint-disable @typescript-eslint/naming-convention */
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.storageService.getJwt()}`,
      })
    };

    try {
      console.log(`[creator-service][getPricesCatalog] Querying to "${queryUrl}"...`);
      const response = await this.http.get<any[]>(queryUrl, httpOptions).toPromise();
      return response;
    } catch (e) {
      console.error('[creator-service][getPricesCatalog] Error while getting rates', e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }

  public async metadataBecomeCreatorRequest() {
    const endpoint = MASTER_SERVER_ENDPOINTS.creator.metadataBecomeCreatorRequest;
    const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}`;

    /* eslint-disable @typescript-eslint/naming-convention */
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.storageService.getJwt()}`,
      })
    };

    try {
      console.log(`[creator-service][metadataBecomeCreatorRequest] Querying to "${queryUrl}"...`);
      const response = await this.http.get<any>(queryUrl, httpOptions).toPromise();
      return response;
    } catch (e) {
      console.error('[creator-service][metadataBecomeCreatorRequest] Error while getting rates', e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }

  public async updateToken() {
    const endpoint = MASTER_SERVER_ENDPOINTS.creator.updateToken;
    const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}`;

    /* eslint-disable @typescript-eslint/naming-convention */
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.storageService.getJwt()}`,
      })
    };

    try {
      console.log(`[creator-service][updateToken] Querying to "${queryUrl}"...`);
      const response = await this.http.get<any>(queryUrl, httpOptions).toPromise();
      console.log(response);
      return response;
    } catch (e) {
      console.error('[creator-service][updateToken] Error while getting token', e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }

  public async getFileMediaCount(creatorId: number) {
    const endpoint = MASTER_SERVER_ENDPOINTS.creator.fileMediaCount;
    const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}`;

    /* eslint-disable @typescript-eslint/naming-convention */
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.storageService.getJwt()}`,
      }),
      params: new HttpParams()
      .append('creatorId', creatorId),
    };
    /* eslint-enable @typescript-eslint/naming-convention */

    try {
      console.log(`[creator-service][getFileMediaCount] Querying to "${queryUrl}"...`);
      const myFans = await this.http.get(queryUrl, httpOptions).toPromise();
      return myFans;
    } catch (e) {
      console.error('[creator-service][getFileMediaCount] Error while getting fans', e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }

  public async setCreatorAditionalInfo(countryform) {
    const endpoint = MASTER_SERVER_ENDPOINTS.creator.aditionalInfo;
    const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}`;

    /* eslint-disable @typescript-eslint/naming-convention */
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.storageService.getJwt()}`,
      })
    };

    const body = JSON.stringify(countryform);

    try {
      console.log(`[creator-service][setCreatorAditionalInfo] Querying to "${queryUrl}"...`);
      const response = await this.http.post(queryUrl, body, httpOptions).toPromise();
      return response;
    } catch (e) {
      console.error('[creator-service][setCreatorAditionalInfo] Error while creating rates request', e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }

  public async getCreatorAditionalInfo() {
    const endpoint = MASTER_SERVER_ENDPOINTS.creator.aditionalInfo;
    const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}`;

    /* eslint-disable @typescript-eslint/naming-convention */
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.storageService.getJwt()}`,
      })
    };

    try {
      console.log(`[creator-service][setCreatorAditionalInfo] Querying to "${queryUrl}"...`);
      const response = await this.http.get(queryUrl, httpOptions).toPromise();
      return response;
    } catch (e) {
      console.error('[creator-service][setCreatorAditionalInfo] Error while creating rates request', e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }
}
