import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CollectionButtonComponent } from './collection-button.component';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
    declarations: [CollectionButtonComponent],
    imports: [
        CommonModule,
        TranslateModule,
        FormsModule,
      IonicModule,    
      ],
        exports: [
          CollectionButtonComponent
        ]
    })

export class CollectionButtonModule { }