import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { Share } from '@capacitor/share';
import { ToastController } from '@ionic/angular';
import { ThemeService } from '../../utils/theme.service'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  // @ViewChild('Search') Search: IonSearchbar;
  // @ViewChild("ButtonContainer") ButtonContainer: ElementRef<HTMLDivElement>;
  // @Input() placeholder_color: string;
  @Input() showBackButton: boolean;
  @Input() showLogo: boolean;
  @Input() showSearchButton: boolean;
  @Input() showAddContentButton: boolean;
  @Input() showCloseButton: boolean;
  @Input() showEditProfile: boolean;
  @Input() showOptionsButton: boolean;
  @Output() clickBack = new EventEmitter<any>();
  @Output() clickClose = new EventEmitter<any>();
  @Input() titleText: string;
  @Input() userProfile?: any;
  @Input() showShareButton: boolean;
  @Input() addClass?: string;
  @Input() idExpandBtn?: string;

  // @Input() search_text: string;
  // @Input() toolbar_color: string;
  // @Input() go_search: string;
  // @Input() data: any;
  // @Input() search_type: string;
  // @Input() filter_category: string;
  // @Input() checkFocus: string;
  platform = Capacitor.getPlatform();
  constructor(
    private router: Router,
    public toastController: ToastController,
    public theme: ThemeService
  ) { }

  ngOnInit() { }

  back() {
    this.clickBack.emit();
  }

  closePage() {
    this.clickClose.emit();
  }

  clickAddButton() {
    this.router.navigateByUrl('/feed/new-post');
  }

  clickEditButton() {
    this.router.navigateByUrl('/profile/edit', { replaceUrl: true });
  }

  goToPage(url: string, params = {}) {
    this.router.navigateByUrl(url, { state: { params } });
  }

  async clickShareButton() {
    if (this.platform === ('ios' || 'android')) {
      await Share.share({
        // text: `Te invito a que te suscribas a @${this.userProfile?.username} en Qhot`,
        url: `https://app.qvery.mx/profile/content-feed/${this.userProfile?.userId}`,
      });
    } else {
      // eslint-disable-next-line max-len
      navigator.clipboard.writeText(`https://app.qvery.mx/profile/content-feed/${this.userProfile?.userId}`);
      const toast = await this.toastController.create({
        message: 'Link copiado al portapapeles.',
        duration: 2000,
        color: 'success',
        cssClass: 'text-centered'
      });
      await toast.present();
    }
  }

}
