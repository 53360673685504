import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import {
  ControlValueAccessor, UntypedFormGroup, UntypedFormBuilder, Validators, Validator, AbstractControl,
  ValidationErrors, NG_VALUE_ACCESSOR, NG_VALIDATORS, ValidatorFn,
} from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: InputComponent
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: InputComponent
    }
  ],
})
export class InputComponent implements OnInit, OnDestroy, ControlValueAccessor, Validator {
  @Input() placeholder?: string;
  @Input() inputType: string | 'text';
  @Input() onlyLetters: boolean;
  @Input() icon?: string;
  @Input() className?: string;
  @Input() validators?: string[];
  @Input() maxlength?: any;
  @Input() minlength?: any;
  @Input() customValidators?: ValidatorFn[];
  @Input() lowercase?: boolean;
  @Input() uppercase?: boolean;
  @Input() inputLabel?: string;
  @Input() readonly?: boolean;
  @Input() password: boolean;
  @Input() numbers?: boolean = false;

  onKeyDown(event: KeyboardEvent) {
    if (this.inputType === 'number') {
      if (event.key === '.' || event.key === '-' || event.key === 'ArrowUp' || event.key === 'ArrowDown') {
        event.preventDefault();
      }
    }
  }
  isPasswordInput = false;

  showPasswordPlaintext = false;

  inputForm: UntypedFormGroup = this.fb.group({
    input: ['', []],
  });

  onChangeSubs: Subscription[] = [];

  validationErrors: ValidationErrors = {};

  // Map to generate "user friendly" errors to show in the UI based on the failed validation
  userFriendlyErrorDetailMap :any; 

  constructor(
    private fb: UntypedFormBuilder,
  ) { }

  // Important to unsuscribe to avoid memory leaks
  ngOnDestroy(): void {
    for (const sub of this.onChangeSubs) {
      sub.unsubscribe();
    }
  }

  ngOnInit() {
    // As soon as the component loads, set the validators specified by the parent component
    this.parseAndSetValidatorsFromParent();

    this.isPasswordInput = this.inputType === 'password' || this.password;
    if(localStorage.getItem('language')==='es'){
      this.userFriendlyErrorDetailMap = {
        required: 'Este campo es requerido',
        email: 'Debe ser un correo electrónico válido',
        noPasswordMatch: 'La contraseña no coincide',
        weakPassword: 'Longitud mínima 6. Combinación de mayúsculas, minúsculas, números y caracteres especiales (!"#%&/()=¿?¡*-+_.:;,)',
        invalidUsername: 'Mínimo 6, máximo 30 letras (a-z), números o símbolos (.-_)',
        invalidName: 'El campo de nombres y apellidos no puede estar vacío, se requiere ingresar mínimo 2 letras.',
      };
    }else if(localStorage.getItem('language')==='en'){
      this.userFriendlyErrorDetailMap = {
        required: 'This field is required',
        email: 'Must be a valid email',
        noPasswordMatch: 'Passwords do not match',
        weakPassword: 'Minimum length 6 characters. Combination of uppercase, lowercase, numbers and special characters (!"#%&/()=¿?¡*-+_.:;,)',
        invalidUsername: 'Minimum 6, maximum 30 letters (a-z), numbers o special characters (.-_)',
        invalidName: 'Name and lastname fields can not be empty, Type in at least 2 letters.',
      };
    }
  }

  shouldShowErrorDetails() {
    return this.inputForm.touched && !this.inputForm.valid;
  }

  getUserFriendlyErrorDetails(): string {
    const validationErrors = this.validationErrors.input;
    let friendlyError :string; 
    if(localStorage.getItem('language')==='es'){
      friendlyError = 'Verifique el valor del campo';
    }else if(localStorage.getItem('language')==='en'){
      friendlyError = 'Check the value';
    }

    // Only show the first validation error that the input has (in case it has more than one)
    const failedValidatorName = Object.keys(validationErrors)[0];
    // Get the display text from the map
    friendlyError = this.userFriendlyErrorDetailMap[failedValidatorName];

    return friendlyError;
  }

  onTouched = () => { };

  onValidatorChange = () => { };

  parseAndSetValidatorsFromParent() {
    if (this.validators || this.customValidators) {
      const inputValidators: ValidatorFn[] = [];

      if (this.customValidators) {
        for (const validatorFn of this.customValidators) {
          inputValidators.push(validatorFn);
        }
      }

      if (this.validators) {
        for (const validatorName of this.validators) {
          inputValidators.push(Validators[validatorName]);
        }
      }

      this.inputForm.get('input').addValidators(inputValidators);
      this.inputForm.get('input').updateValueAndValidity();
      this.onValidatorChange();
    }
  }

  togglePlaintextPassword() {
    this.showPasswordPlaintext = !this.showPasswordPlaintext;
    this.inputType = this.showPasswordPlaintext ? 'text' : 'password';
  }

  writeValue(value: any): void {
    if (value) {
      this.inputForm.setValue(value, { emitEvent: false });
    }
  }

  registerOnChange(onChangeFn: (...args: unknown[]) => void): void {
    const onChangeSub = this.inputForm.valueChanges.subscribe(onChangeFn);
    this.onChangeSubs.push(onChangeSub);
  }

  registerOnTouched(onTouchedFn: (...args: unknown[]) => void): void {
    this.onTouched = onTouchedFn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.inputForm.disable();
    } else {
      this.inputForm.enable();
    }
  }

  registerOnValidatorChange(validatorChangeFn: () => void): void {
    this.onValidatorChange = validatorChangeFn;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (this.inputForm.valid) {
      return null;
    }

    const errors = {
      input: this.inputForm.get('input').errors,
    };

    this.validationErrors = errors;
    return errors;
  }
}
