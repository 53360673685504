/* eslint-disable max-len */
import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild, SimpleChanges, OnChanges, AfterViewInit } from '@angular/core';
import {
  Publication, User, PublicationPaymentDetails, PublicationRequestDetails,
  PaymentIntentRequest
} from '@blackfor-mexico/qvery-dto-interfaces';
// eslint-disable-next-line max-len
import { PaidContentTypes, PublicationComment, PublicationContent, PublicationLocation, PublicationReaction } from 'src/app/pages/profile/content-feed/content-feed.page';
import { PublicationService, isFeedPublication, isFeedAnnouncement, isEndedLiveVideoPublication } from 'src/app/services/publication.service';
import * as dayjs from 'dayjs';
import 'dayjs/locale/es-mx'; // load on demand
import 'dayjs/locale/en'; // load on demand
import * as utc from 'dayjs/plugin/utc';
import * as relativeTime from 'dayjs/plugin/relativeTime';
import { StorageService } from 'src/app/services/storage.service';
import { CommentsService } from 'src/app/services/comments.service';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { Swiper } from 'swiper';
import Pagination from 'swiper';
import Zoom from 'swiper';
import { PaymentsService } from 'src/app/services/payments.service';
import { Capacitor } from '@capacitor/core';
import { File as CordovaFile } from '@awesome-cordova-plugins/file';
import { FileTransfer, FileTransferObject } from '@awesome-cordova-plugins/file-transfer/ngx';
import { PhotoLibrary } from '@awesome-cordova-plugins/photo-library/ngx';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CreatorSearchService } from 'src/app/services/creator-search.service';
import { IonIcon, ToastController } from '@ionic/angular';
import { ThemeService } from '../../utils/theme.service';

dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.locale('es-mx');

/* eslint-disable @typescript-eslint/naming-convention */
export interface FeedPublication extends UserFeedResponse {
  content: PublicationContent;
}
export interface CreatorPublicationsResponse {
  'Comments': PublicationComment[];
  'Publication_Reactions': PublicationReaction[];
  creator: Pick<User, 'profilePictureKey' | 'username'>;
  creatorId: number;
  isPublic: number;
  location: PublicationLocation;
  publicationId: number;
  publicationType: {
    name: string;
  };
  publicationTypeId: number;
  reactionCount: number;
  Media?: any;
}
export interface AnnouncementContent {
  createdAt: string;
  date: string;
  description: string;
  image: string;
  title: string;
  media?: any;
}
export interface CreatorAnnouncementsResponse extends CreatorPublicationsResponse {
  content: AnnouncementContent;
}
export interface CreatorPostsResponse extends CreatorPublicationsResponse {
  price?: any;
  hasCurrentUserBought?: boolean | null;
  content: PublicationContent;
}

export interface UserFeedResponse extends Publication {
  content: PublicationContent;
  location: PublicationLocation;
  reactionCount: number;
  publicationType: {
    name: string;
  };
  creator: Pick<User, 'username' | 'profilePictureKey'>;
  'Publication_Reactions': PublicationReaction[];
  'Comments': PublicationComment[];
  hasCurrentUserReacted: boolean;
  currentUserReactionId: number;
  hasCurrentUserBought?: number | null;
  price?: any | null;
  Media?: any[];
}

const likedByYou = (obj) => obj.hasCurrentUserReacted;
const savedInCollection = (obj) => obj.hasSavedInCollection;


@Component({
  selector: 'app-post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.scss'],
})


export class PostComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() publication: UserFeedResponse;
  @Input() indexOfelement: number;
  @Input() platform: string;
  @Input() userProfile: User;
  @Input() isPaymentInProgress: boolean;
  @Input() postIsClosed: object;
  @Input() addClass: string;

  @Output() openReportPublication: EventEmitter<any> = new EventEmitter();
  @Output() setReactionsAndOpenModal: EventEmitter<any> = new EventEmitter();
  @Output() setPhotoAndOpenModal: EventEmitter<any> = new EventEmitter();
  @Output() blockUser: EventEmitter<any> = new EventEmitter();
  @Output() deleteComment: EventEmitter<any> = new EventEmitter();
  @Output() openCommentsPage: EventEmitter<any> = new EventEmitter();
  @Output() openReportComment: EventEmitter<any> = new EventEmitter();


  // Payment controllers from page
  // two params. true/false, No. step.
  @Output() openPaymentModal: EventEmitter<any> = new EventEmitter();
  // @params: message, color
  @Output() handleToast: EventEmitter<any> = new EventEmitter();
  @Output() handleIsPaymentInProgress: EventEmitter<any> = new EventEmitter();

  @Output() clickButton: EventEmitter<any> = new EventEmitter();

  @ViewChild('optionsPostComponent') popover;
  @ViewChild('reactionIcon') reactionIcon: any;
  @ViewChild('video') videoPlayer: ElementRef;
  @ViewChild('videoTimeLine') videoTimeLine: ElementRef;
  // @ViewChild('innerElement') innerElement: ElementRef;
  @ViewChild('swiper') swiperRef: ElementRef<HTMLElement & { swiper?: Swiper } & { initialize: () => void }> | undefined;
  swiper?: Swiper;

  isCreator = false;

  youLiked = false;
  uid;
  jwt;

  iconPlayPause = 'pause';
  iconMute = 'volume-off';
  newAdRoute = '/feed/adverts/edit';
  editPostRoute = 'feed/edit-post';

  objPostDeleting;
  deleting = false;
  showModalConfirmDeletePost = false;

  isCurrentUserBlocked = false;
  listOfBlockedUsers: any = [];

  activeIndexSlider = 0;

  postGlassfy;
  pricePostGlassfy;
  // paymentMethods?: CardData[];
  videoWasPaused = false;
  firstTimeVideoAppear = true;
  isMyOwnProfile = false;
  type: string;
  showModalDonate = false;
  donationStep = 1;
  donationCommentMaxLength = 100;
  donationForm: UntypedFormGroup = this.formBuilder.group({
    comment: ['', [Validators.maxLength(this.donationCommentMaxLength)]]
  });

  currentTimeVideo = '';
  durationVideo = '';
  isIntersecting = false;
  observer;

  creatorIdToView = 0;
  creatorAnnouncements: CreatorAnnouncementsResponse[] = [];
  creatorPosts: CreatorPostsResponse[] = [];
  creatorVideos: CreatorPostsResponse[] = [];
  hasSavedInAnyCollection = false;

  isPopoverOpen = false;

  currentTimeVideoTimeLine = 0;
  durationVideoTimeLine = 0;
  isSubscribed: boolean;

  constructor(
    private storage: StorageService,
    public publicationService: PublicationService,
    private commentService: CommentsService,
    public toastController: ToastController,
    private creatorSearch: CreatorSearchService,
    private router: Router,
    private user: UserService,
    private paymentsService: PaymentsService,
    private transfer: FileTransfer,
    private photoLibrary: PhotoLibrary,
    private formBuilder: UntypedFormBuilder,
    public theme: ThemeService
  ) { }


  async ngOnInit() {
    if(localStorage.getItem('language')==='es'){
      dayjs.locale('es-mx');
    }else if(localStorage.getItem('language')==='en'){
      dayjs.locale('en');
    }
    const jwt = await this.storage.getJwtAsJson();
    this.uid = jwt.uid;
    this.jwt = jwt;
    this.creatorIdToView = this.publication.creatorId || this.jwt.uid;
    this.youLiked = likedByYou(this.publication);
    const creatorProfile = await this.user.getUserProfile(this.publication.creatorId);
    this.isSubscribed = creatorProfile.isSubscribed;
    if (this.publication.content.type.includes('image')) {
      this.type = 'image';
    } else if (this.publication.content.type.includes('video')) {
      this.type = 'video';
    }
    this.hasSavedInAnyCollection = savedInCollection(this.publication);

    if (jwt.uty === 2) {
      this.userIsBlocked(this.publication.creatorId);
    }
    await this.getListBlockedUsers();
    this.publication.Comments = await this.getCommentsNotBlocked(this.publication.Comments);
    if (this.publication.creatorId === this.uid) {
      this.isMyOwnProfile = true;
    } else {
      this.isMyOwnProfile = false;
    }
    this.filterBlurImages();
    //   if (this.publication.price && this.platform === 'ios') {
    //     this.postGlassfy = await this.productService.getProduct(`publication_${this.publication.publicationId}_${this.publication.creatorId}`);
    //     this.pricePostGlassfy = await this.postGlassfy.product.price;
    //   }
  }

  ngAfterViewInit(): void {
    let isAutoHeightEnable = false;
    let swiperEl;
    if (this.publication.content.media.length > 1) {
      isAutoHeightEnable = true;
    }
    swiperEl = Object.assign(this.swiperRef.nativeElement, {
      modules: [Pagination, Zoom],
      pagination: { clickable: false },
      slidesPerView: 1,
      centeredSlides: true,
      lazy: true,
      autoHeight: isAutoHeightEnable
    });

    this.swiper = this.swiperRef.nativeElement.swiper;

    this.publication.Media.forEach(async (mediaElement) => {
      if (this.publicationService.mediaIsImage(mediaElement) && !this.publicationService.isMediaNotShowable(mediaElement)) {
        this.swiper.zoom.enable();
      }

      if (this.publicationService.mediaIsVideo(mediaElement)) {
        while (!this.videoPlayer?.nativeElement) {
          await new Promise(resolve => setTimeout(resolve, 50));
        }
        this.videoPlayer?.nativeElement.addEventListener('timeupdate', () => {
          const durationDateObj = new Date(this.videoPlayer.nativeElement.currentTime * 1000).toISOString().substr(11, 8);
          this.currentTimeVideo = ((durationDateObj.substr(0, 2) != '00') ? (durationDateObj.substr(0, 2) + ':') : '') + durationDateObj.substr(3, 2) + ':' + durationDateObj.substr(6, 2);
          this.videoTimeLine.nativeElement.value = this.videoPlayer.nativeElement.currentTime;

          try {
            if (this.durationVideo === '') {
              const durationTotalDateObj = new Date(this.videoPlayer.nativeElement.duration * 1000).toISOString().substr(11, 8);
              this.durationVideo = ((durationTotalDateObj.substr(0, 2) != '00') ? (durationTotalDateObj.substr(0, 2) + ':') : '') + durationTotalDateObj.substr(3, 2) + ':' + durationTotalDateObj.substr(6, 2);
            }
          } catch { }
        });



        // list of options
        let options = {
          threshold: [0.75],
        };

        // instantiate a new Intersection Observer
        this.observer = new IntersectionObserver(this.onEntryVideo, options);
        this.observer.observe(this.videoPlayer?.nativeElement);

        this.videoPlayer.nativeElement.addEventListener('loadedmetadata', () => {
          this.durationVideoTimeLine = this.videoPlayer.nativeElement.duration;
        });
      }
    });

    swiperEl.initialize();

  }

  updateTime() {
    this.videoTimeLine.nativeElement.value = this.videoPlayer.nativeElement.currentTime;
  }

  seekTo(event: Event) {
    const target = event.target as HTMLInputElement;
    const currentTime = parseFloat(target.value);
    this.videoTimeLine.nativeElement.value = currentTime;
    this.videoPlayer.nativeElement.currentTime = currentTime;
    const durationDateObj = new Date(currentTime * 1000).toISOString().substr(11, 8);
    this.currentTimeVideo = ((durationDateObj.substr(0, 2) != '00') ? (durationDateObj.substr(0, 2) + ':') : '') + durationDateObj.substr(3, 2) + ':' + durationDateObj.substr(6, 2);
  }


  ngOnChanges(changes: SimpleChanges) {
    // eslint-disable-next-line @typescript-eslint/dot-notation
    const currentVal = changes['postIsClosed'].currentValue;
    if (currentVal?.postSelected === this.publication) {
      if (this.publication.Media[currentVal?.indexOfMediaSelected] && this.publication.Media[currentVal?.indexOfMediaSelected].mimeType.includes('video')) {
        if (this.videoWasPaused) {
          this.videoWasPaused = false;
          this.playPauseVideo();
        }
      }
    }
  }


  editAd(selectedAdIndex: number) {
    this.router.navigate([this.newAdRoute], { state: { ad: this.creatorAnnouncements[selectedAdIndex] } });
  }

  editPublication(selectedPostIndex: number, type: string) {
    let postToEdit;
    if (type === 'image') {
      postToEdit = this.publication;
    }
    else if (type === 'video') {
      postToEdit = this.publication;
    }
    this.router.navigate([this.editPostRoute], { state: { post: postToEdit } });
  }



  async presentPaymentErrorToast(errorMessage: string, duration: number) {
    const toast = await this.toastController.create({
      message: errorMessage,
      duration,
      color: 'danger',
      cssClass: 'text-centered ',  //custom-toast-bold-text
      position: 'top',
    });
    await toast.present();
  }

  handleDeletePublication() {
    this.showModalConfirmDeletePost = true;
  }

  async onDeletePublication() {
    this.deleting = true;
    let id;
    id = this.publication.publicationId;
    try {

      await this.publicationService.deletePublication(id);

      const toast = await this.toastController.create({
        message: 'Post eliminado',
        duration: 2000,
        color: 'primary',
        cssClass: 'text-centered'
      });
      await toast.present();
    }
    catch (e) {
      console.error(e);
      this.presentPaymentErrorToast('Ocurrió un error eliminando tu post', 2000);
    }
    finally {
      this.objPostDeleting = undefined;
      this.deleting = false;
      this.showModalConfirmDeletePost = false;
    }
  }


  onEntryVideo(entry) {
    entry.forEach((change) => {
      if (change.isIntersecting) {
        change.target.play();
        const icon = document.getElementById('iconPlayPause') as unknown as IonIcon;
        icon.name = 'pause';
      } else {
        change.target.pause();
        const icon = document.getElementById('iconPlayPause') as unknown as IonIcon;
        icon.name = 'play-circle';
      }
    });
  }

  parseDate(d: string) {
    return dayjs.utc(d).fromNow(true);
  }

  expandModal(params) {
    if (!this.showBlur()) {
      if (this.publication.Media[params.indexOfMedia] && this.publication.Media[params.indexOfMedia].mimeType.includes('video')) {
        if (!this.videoPlayer.nativeElement.paused) {
          this.playPauseVideo();
          this.videoWasPaused = true;
        }
        this.videoPlayer.nativeElement.pause();
      }
      this.setPhotoAndOpenModal.emit(params);
    }
  }

  parsePublicationDate(feedResponse: UserFeedResponse) {
    let publicationDate: string;
    if (isFeedPublication(feedResponse)) {
      publicationDate = feedResponse.createdAt.toString();
    } else if (isFeedAnnouncement(feedResponse)) {
      publicationDate = feedResponse.content.date;
    } else if (isEndedLiveVideoPublication) {
      publicationDate = feedResponse.createdAt.toString();
    }
    return this.parseDate(publicationDate);
  }

  async getListBlockedUsers() {
    this.listOfBlockedUsers = await this.commentService.getCommentUserBlocked();
  }

  getCommentsNotBlocked(list: Array<any>) {
    this.listOfBlockedUsers.forEach((block) => {
      list = list.filter(obj => obj.userId !== block.blockedUserId);
    });
    return list;
  }

  showReactionNames(reactions: PublicationReaction[]) {
    let arrayNames = '';
    reactions.slice(0, 3).forEach((reaction) => {
      arrayNames += reaction.user.username + ', ';
    });
    return arrayNames;
  }

  verifyIfCommentLiked(comment) {
    return comment.hasCurrentUserReacted;
  }

  async reactionPostClicked() {
    if (!likedByYou(this.publication)) {
      const form = {
        userId: this.uid,
        publicationId: this.publication.publicationId,
        reactionTypeId: 1
      };

      /// ADD PUSH TO THE REACTIONS IN FRONT FOR AVOID BROKE THIS
      await this.publicationService.createPublicationReaction(form).then((res: any) => {
        this.youLiked = true;
        this.publication.hasCurrentUserReacted = true;
        this.publication.currentUserReactionId = res.body.publicationReactionId;
        this.publication.reactionCount += 1;
      });
    }
    else {
      const form = {
        publicationReactionId: this.publication.currentUserReactionId
      };
      await this.publicationService.deletePublicationReaction(form).then(() => {
        this.youLiked = false;
        this.publication.hasCurrentUserReacted = false;
        this.publication.currentUserReactionId = undefined;
        this.publication.reactionCount -= 1;
      });
    }
  }

  // Click Like button in comment, verify if you liked or not and add or remove the
  // reaction to the db and temporally added to the array to avoid reload all the feed fetch
  async reactionCommentClicked(comment, indexOfElement) {
    if (!this.verifyIfCommentLiked(comment)) {
      const form = {
        userId: this.uid,
        commentId: comment.commentId,
        reactionTypeId: 1
      };
      await this.commentService.createCommentsReaction(form).then((res: any) => {
        this.publication.Comments[indexOfElement].hasCurrentUserReacted = true;
        this.publication.Comments[indexOfElement].currentUserReactionId = res.body.commentReactionId;
      });
    }
    else {
      const reaction = comment.Comment_Reactions.find(el => el.userId === this.uid);
      const form = {
        commentReactionId: comment.currentUserReactionId
      };
      await this.commentService.deleteCommentsReaction(form).then(() => {
        const indComment = this.publication.Comments.findIndex(el => el.commentId === comment.commentId);
        this.publication.Comments[indComment].currentUserReactionId = null;
        this.publication.Comments[indComment].hasCurrentUserReacted = false;
      });
    }
  }

  commentBtnClicked() {
    this.router.navigate(['feed/publication/' + this.publication.publicationId + '/comments']);
  }

  playPauseVideo() {
    if (this.videoPlayer.nativeElement.paused) {
      this.videoPlayer.nativeElement.play();
      this.iconPlayPause = 'pause';
    } else if (!this.videoPlayer.nativeElement.paused) {
      this.videoPlayer.nativeElement.pause();
      this.iconPlayPause = 'play-circle';
    }
  }

  muteVideo() {
    if (this.videoPlayer.nativeElement.muted) {
      this.videoPlayer.nativeElement.muted = false;
      this.iconMute = 'volume-high';
    } else if(!this.videoPlayer.nativeElement.muted) {
      this.videoPlayer.nativeElement.muted = true;
      this.iconMute = 'volume-off';
    }
  }

  rewind() {
    this.videoPlayer.nativeElement.currentTime -= 5;
  }

  // forward the current time
  forward() {
    this.videoPlayer.nativeElement.currentTime += 5;
  }

  calculatePriceStripe(price) {
    const comissionStripe = parseFloat(((price * 0.036) + 3).toFixed(2));
    const total = parseFloat(price.toString()) + comissionStripe;
    return total.toFixed(2);
  }



  isCommentDeleted(_comment) {
    return _comment.content.comment === 'Este comentario fue eliminado';
  }

  async userIsBlocked(creatorId) {
    this.isCurrentUserBlocked = await this.user.getUserIsBlocked(creatorId) ? true : false;
  }

  goToProfile(userId: number) {
    this.router.navigate(['profile/content-feed/' + userId]);
  }


  async onSlideChange([swipe]) {
    this.activeIndexSlider = swipe.activeIndex;
    swipe.updateAutoHeight(500);
  }

  publicationIsVisibleForThisUser() {
    if (this.publication.price) {
      return this.publication?.hasCurrentUserBought || this.publication?.creatorId === this.uid;
    }
    return true;
  }

  getMimeType() {
    return this.publication.Media[0].mimeType;
  }

  async openPdf() {
    const url = this.publication.content.media[0];
    const data = await fetch(url).then(res => res.blob())
      .then(blob => {
        const objectURL = URL.createObjectURL(blob);
        this.router.navigate(['/feed/file'], { state: { pdfFile: objectURL, description: this.publication.content.description } });
      });
  }

  async downloadPdf() {
    try {
      // Create a download link
      const downloadLink = document.createElement('a');
      downloadLink.href = this.publication.content.media[0];
      downloadLink.target = '_blank';
      downloadLink.download = 'pdf-' + this.publication.creator.username + '-' + this.publication.content?.createdAt + '.pdf';

      // Trigger the download
      document.body.appendChild(downloadLink);
      downloadLink.click();

      // Clean up
      setTimeout(() => {
        URL.revokeObjectURL(downloadLink.href);
        document.body.removeChild(downloadLink);
      }, 100);
    } catch (error) {
      console.error('Error downloading the file:', error.message);
    }
  }



  download() {
    const extension = this.publication?.content?.media[0].split('.')[3];
    const filename = `${CordovaFile.dataDirectory}archivo-${dayjs().format('YYYY-MM-DD HH:mm:ss')}.${extension}`;
    if (Capacitor.getPlatform() !== 'ios') {
      try {
        const url = this.publication?.content?.media[0];

        const downloadLink = document.createElement('a');
        downloadLink.href = url;
        downloadLink.download = filename;
        document.body.appendChild(downloadLink);
        downloadLink.click();

        // Clean up
        setTimeout(() => {
          URL.revokeObjectURL(downloadLink.href);
          document.body.removeChild(downloadLink);
        }, 100);
      } catch (error) {
        console.error('Error downloading the file:', error.message);
      }
    } else {
      const fileTransfer: FileTransferObject = this.transfer.create();
      const url = encodeURI(this.publication?.content?.media[0]);
      // eslint-disable-next-line max-len
      // const url = 'https://label.averydennison.com/content/dam/averydennison/lpm-responsive/na/images/top%20level%20nav/avery-dennison-logo-red-rgb.png';
      // eslint-disable-next-line max-len
      fileTransfer.download(url, filename).then((entry) => {
        const urlDownload = entry.toURL();
        if (extension === ('mp4' || 'mov' || 'mpeg')) {
          this.photoLibrary.saveVideo(urlDownload, 'Qvery');
        }
        if (extension === ('jpeg' || 'png' || 'jpg' || 'gif')) {
          this.photoLibrary.saveImage(urlDownload, 'Qvery');
        }

        // ${extension}
      }, (error) => {
        // handle error
      });
    }
  };

  async openDonateModal() {
    this.showModalDonate = true;
  }

  openOptions(e: Event) {
    this.popover.event = e;
    this.isPopoverOpen = true;
  }

   showBlur() {
    if (this.isSubscribed || this.publication?.creatorId === this.uid) {
      return false;
    }
    return true;
  }

   filterBlurImages() {
    if (!this.showBlur()) {
      this.publication.Media = this.publication.Media.filter(
        (mediaElement) =>
          !mediaElement.mediaItemKey || !mediaElement.mediaItemKey.includes('blur')
      );
    }
  }

}


