import { Component, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationsService, NotificationsCount } from 'src/app/services/notifications.service';
import { ActionSheetController } from '@ionic/angular';
import { ViewportScroller } from '@angular/common';
import { ThemeService } from '../../utils/theme.service'

@Component({
  selector: 'app-footer-navbar',
  templateUrl: './footer-navbar.component.html',
  styleUrls: ['./footer-navbar.component.scss'],
})
export class FooterNavbarComponent implements OnInit {
  @Input() tabSelected: string;
  @Input() userProfile?: any;
  @Input() isCreator?: boolean;
  @Input() addClass?: string;
  @ViewChild('popoverMobile') popoverMobile;
  @ViewChild('popoverDesktop') popoverDesktop;

  isOpenMobile = false;
  isOpenDesktop = false;

  popoverOptions: any = [
    { id: 0, icon: "copy-outline", optionName: "Publicación" },
    { id: 1, icon: "calendar-clear-outline", optionName: "Anuncio" }
  ]

  notificationsCount: Promise<NotificationsCount>;
  tooManyNotifications = false;
  varNotifications: number;

  constructor(
    private router: Router,
    private notificationsService: NotificationsService,
    private actionSheetCtrl: ActionSheetController,
    private zone: NgZone,
    private viewPortScroller: ViewportScroller,
    public theme: ThemeService
  ) { }

  async ngOnInit() {
    this.notificationsCount = this.notificationsService.getNotificationsCount();
    this.varNotifications = (await this.notificationsCount).count;
    //  console.log(this.varNotifications);

    if (this.varNotifications > 99) {
      this.tooManyNotifications = true;
    } else {
      this.tooManyNotifications = false;
    }
  }

  navigateTo(url: string) {
    this.router.navigate([url], { replaceUrl: true });
  }

  clickAddButton() {
    this.router.navigateByUrl('/feed/new-post');
  }
  goToPage(url: string, params = {}) {
    this.router.navigateByUrl(url, { state: { params } });
  }

  // async presentActionSheet() {
  //   var postText :string; 
  //   var announcementText: string; 
  //   if(localStorage.getItem('language')==='es'){
  //       postText ='Publicación';
  //       announcementText='Anuncio';
  //   }else if(localStorage.getItem('language')==='en'){
  //     postText ='Post';
  //     announcementText='Announcement';
  //   }
  //   const actionSheet = await this.actionSheetCtrl.create({
  //     cssClass: 'btn-action-sheet',
  //     buttons: [
  //       {
  //         text: postText,
  //         icon: 'copy-outline',
  //         cssClass: 'btn',
  //         handler:
  //           () => {
  //             this.zone.run(() => {
  //               this.goToPage('/feed/new-post');
  //             });
  //           }
  //         ,
  //       },
  //       // {
  //       //   text: 'En vivo',
  //       //   icon: 'videocam-outline',
  //       //   cssClass: 'btn',
  //       //   handler:
  //       //     () => {
  //       //       this.zone.run(() => {
  //       //         this.goToPage('/feed/live', this.userProfile);
  //       //       });
  //       //     }
  //       //   ,
  //       // },
  //       {
  //         text: announcementText,
  //         icon: 'calendar-clear-outline',
  //         cssClass: 'btn',
  //         handler:
  //           () => {
  //             this.zone.run(() => {
  //               this.goToPage('/feed/adverts/new');
  //             });
  //           }
  //         ,
  //       },
  //     ],
  //   });

  //   await actionSheet.present();

  //   const result = await actionSheet.onDidDismiss();
  // }

  validateNavigation() {
    if (this.tabSelected === 'home') {
      const container = document.getElementById('top-page');
      container.scrollIntoView({ behavior: 'smooth' });
    } else {
      this.navigateTo('/feed');
    }
  }

  presentPopover(e: Event) {
    this.popoverMobile.event = e;
    this.isOpenMobile = !this.isOpenMobile;
  }
  presentPopoverDesktop(e: Event) {
    this.popoverDesktop.event = e;
    this.isOpenDesktop = !this.isOpenDesktop;
  }

  goToPageOptionPopover(option: number) {
    this.isOpenMobile = false;
    this.isOpenDesktop = false;
    if (option === 0) {
      this.zone.run(() => {
        this.goToPage('/feed/new-post');
      });
    } else if (option === 1) {
      this.zone.run(() => {
        this.goToPage('/feed/adverts/new');
      });
    }
  }

}
