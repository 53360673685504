import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { AnnouncementComponent } from './announcement.component';
import { CollectionButtonModule } from '../collection-button/collection-button.module';
import { ModalModule } from 'src/app/components/modal/modal.module';
import { MainButtonModule } from '../main-button/main-button.module';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  declarations: [AnnouncementComponent],
  imports: [
    CommonModule,
    TranslateModule,
    IonicModule,
    MainButtonModule,
    ModalModule,
    CollectionButtonModule
  ],
  exports: [
    AnnouncementComponent
  ]
})
export class AnnouncementModule { }
