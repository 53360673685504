import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AreaOfInterest } from '@blackfor-mexico/qvery-dto-interfaces';
import { CheckboxCustomEvent } from '@ionic/core';
import { AreaOfInterestService } from 'src/app/services/area-of-interest.service';
import { UserService } from 'src/app/services/user.service';
import { StorageService } from 'src/app/services/storage.service';
import { Location } from '@angular/common';


export interface AoiCardClickCustomEvent {
  clickedArea: AreaOfInterest;
}

@Component({
  selector: 'app-areas-of-interest',
  templateUrl: './areas-of-interest.component.html',
  styleUrls: ['./areas-of-interest.component.scss'],
})
export class AreasOfInterestComponent implements OnInit {
  @Input() showCheckboxOnAreas = false;

  @Output() aoiCardClick = new EventEmitter<any>();

  areaOfInterestCatalog: AreaOfInterest[] = [];

  areaOfInterestUser: any = [];

  constructor(
    private areaOfInterestService: AreaOfInterestService,
    private user: UserService,
    private storage: StorageService,
    private location: Location,
  ) { }

  async ngOnInit() {
    this.areaOfInterestCatalog = await this.getAreaOfInterestCatalog();
    this.areaOfInterestUser = await this.getAreaOfInterestUser();
    this.setAreaOfInterestUser();

  }

  async getAreaOfInterestCatalog(): Promise<AreaOfInterest[]> {
    try {
      return this.areaOfInterestService.getAreaOfInterestCatalog();
    } catch (e) {
      console.error('Unable to fetch areas of interest', e);
    }
  }

  async getAreaOfInterestUser() {
    try {
      const jwt = await this.storage.getJwtAsJson();
      return this.user.getAreasOfInterestUser(jwt.uid);
    } catch (e) {
      console.error('Unable to fetch areas of interest', e);
    }
  }

  setAreaOfInterestUser() {
    for (const key in this.areaOfInterestCatalog) {
      var areInterestSelected = this.areaOfInterestUser.find(element => element.areaOfInterestId === this.areaOfInterestCatalog[key].areaOfInterestId);
      if (areInterestSelected == undefined) {
        this.areaOfInterestCatalog[key]["check"] = false;
      } else {
        this.areaOfInterestCatalog[key]["check"] = true;

        const pagePath = this.location.path();
         
        if (!pagePath.includes('search')) {
          const clickedArea = this.areaOfInterestCatalog[key];
          this.aoiCardClick.emit({ clickedArea });
        }
      }
    }
  }


  onCardClick(_event: Event, cardIndex: number) {    
    this.areaOfInterestCatalog[cardIndex]["check"] = !this.areaOfInterestCatalog[cardIndex]["check"];
    const clickedArea = this.areaOfInterestCatalog[cardIndex];
    this.aoiCardClick.emit({ clickedArea });
  }

}
