import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-download-app',
  templateUrl: './download-app.component.html',
  styleUrls: ['./download-app.component.scss'],
})

export class DownloadAppComponent implements OnInit {
  @Input() show: boolean;
  @Input() isUpdate: boolean;

  platform;

  constructor() { }

  ngOnInit() {
    this.platform = navigator.userAgent.match(/iPhone|iPad|iPod/i) ? 'ios' : 'android';
  }

  goToStore(platform: string) {
    if (platform === 'ios') {
      window.open('https://apps.apple.com/us/app/qvery/id1660139173', '_blank');
    }
    else if (platform === 'android') {
      window.open('https://play.google.com/store/apps/details?id=com.qvery.mx&pcampaignid=web_share', '_blank');
    }
  }
  
}
