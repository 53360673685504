import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import {RouterModule} from '@angular/router';
import { CarrouselComponent } from './carrousel.component';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
  declarations: [ CarrouselComponent ],
  imports: [
    CommonModule,
    TranslateModule,
    IonicModule,
    RouterModule
  ],
  exports: [
    CarrouselComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CarrouselModule { }
