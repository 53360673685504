import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalizeFirst'
})
export class CapitalizeFirstPipe implements PipeTransform {

  transform(value: string, ..._args: unknown[]): string {
    const firstChar = value[0];
    const slicedString = value.slice(1);
    return `${firstChar.toUpperCase()}${slicedString.toLowerCase()}`;
  }

}
