import { AreasOfInterestComponent } from './areas-of-interest.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms'; 
import { CustomPipesModule } from 'src/app/utils/custom-pipes/custom-pipes.module';

@NgModule({
  declarations: [
    AreasOfInterestComponent,
  ],
  exports: [
    AreasOfInterestComponent,
  ],
  imports: [
    CommonModule,
    CustomPipesModule,
    IonicModule,
    FormsModule
  ]
})
export class AreasOfInterestModule { }
