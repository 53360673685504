import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { loginAndUserRegisterRoutes, shareProfileRoute, sharePublicationRoute } from 'src/app/app-routing.module';
import { StorageService } from 'src/app/services/storage.service';
import { Capacitor } from '@capacitor/core';

@Injectable()
export class AuthGuard implements CanActivate {
  private platform = Capacitor.getPlatform();
  constructor(
    private router: Router,
    private storageService: StorageService,
  ) { }

  /**
   * Custom guard function that checks whether the requesting user is already logged in to the app.
   *
   * @param route ActivatedRouteSnapshot
   * @param state RouterStateSnapshot
   * @returns A boolean or an UrlTree object if users should be redirected
   */
  async canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const isMobileDevice = window.navigator.userAgent.toLowerCase().includes("mobi");
    const unauthorizedRedirectTree: UrlTree = this.router.parseUrl('/welcome');
    const currentUrl = state.url;
    const storedJwt = await this.storageService.getJwt();
    const isUserAuthenticated = Boolean(storedJwt);
    console.log('[authGuard] currentUrl:', currentUrl);
    console.log('[authGuard] isUserAuthenticated:', isUserAuthenticated);

    // Verify if is a mobile from browser
      if (currentUrl.match((shareProfileRoute) || (sharePublicationRoute))) {
        return true;
      }
    
    // Automatically redirect logged users to /feed if they visit the login or register pages
    if (loginAndUserRegisterRoutes.includes(currentUrl)) {
      return isUserAuthenticated ? this.router.parseUrl('/feed') : true;
    }

    // TODO: Parse the JWT into a JSON and check if the "exp" claim is still valid
    return !isUserAuthenticated ? unauthorizedRedirectTree : true;
  }
}
