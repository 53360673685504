import { Component, OnInit , Input, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-main-button',
  templateUrl: './main-button.component.html',
  styleUrls: ['./main-button.component.scss'],
})
export class MainButtonComponent implements OnInit {
  @Input() buttonText: string;
  @Input() buttonType: string | 'button';
  @Input() disabled: boolean | false;
  @Input() color?: string;
  @Input() largeButton: boolean | false;
  @Input() mediumButton?: boolean | false;
  @Input() smallButton?: boolean | false;
  @Output() clickButton: EventEmitter<any> = new EventEmitter();
  constructor() { }

  ngOnInit() {}

}
