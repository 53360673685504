/* eslint-disable no-underscore-dangle */
import { Injectable } from '@angular/core';
import { QveryJwtStructure } from '@blackfor-mexico/qvery-dto-interfaces';
import { Capacitor } from '@capacitor/core';

import { Storage } from '@ionic/storage-angular';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private _storage: Storage | null;

  private memoryStorage;

  private isLocalStorage = true;

  // Reference to the initial promise where the storage is created
  private storageCreatedPromise: Promise<void>;

  // Flag to ensure all get/set methods wait for the storage to be created
  private isStorageReady = false;

  constructor(
    private storage: Storage,
  ) {
    this.storageCreatedPromise = this.init();
  }

  async init() {
    const platform = Capacitor.getPlatform();
    console.log('Platform: ', platform);
    console.log('Environment: ', environment);

    if (environment.production) {
      //if it is Prod environment only use local storage on devices
      this.isLocalStorage = Capacitor.isNativePlatform();
    }

    if (this.isLocalStorage) {
      // If using, define drivers here: await this.storage.defineDriver(/*...*/);
      const storage = await this.storage.create();
      this._storage = storage;
    } else {
      this.memoryStorage = {};
    }
  }

  public async set(key: string, value: any) {
    await this.ensureStorageIsCreated();

    if (this.isLocalStorage) {
      return this._storage?.set(key, value);
    } else {
      this.memoryStorage[key] = value;
    }
  }

  public async get(key: string) {
    await this.ensureStorageIsCreated();

    if (this.isLocalStorage) {
      return this._storage?.get(key);
    } else {
      return this.memoryStorage[key];
    }
  }

  public async remove(key: string) {
    await this.ensureStorageIsCreated();

    if (this.isLocalStorage) {
      return this._storage?.remove(key);
    } else {
      delete this.memoryStorage[key];
    }
  }

  public async setJwt(jwt: string) {
    await this.ensureStorageIsCreated();
    return this.set('jwt', jwt);
  }

  public async getJwt(): Promise<string> {
    await this.ensureStorageIsCreated();
    return this.get('jwt');
  }

  public async deleteJwt() {
    await this.ensureStorageIsCreated();
    return this.remove('jwt');
  }

  public async getJwtAsJson(): Promise<QveryJwtStructure> {
    const token = await this.getJwt();
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(atob(base64));
  }

  private async ensureStorageIsCreated(): Promise<void> {
    if (!this.isStorageReady) {
      await this.storageCreatedPromise;
    }
  }
}
