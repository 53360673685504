import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';

// Qvery specific modules & components
import { AnnouncementModule } from './app/components/announcement/announcement.module';
import { AreasOfInterestModule } from './app/components/areas-of-interest/areas-of-interest.module';
import { CarrouselModule } from './app/pages/feed/carrousel/carrousel.module';
import { CustomPipesModule } from './app/utils/custom-pipes/custom-pipes.module';
import { FooterNavbarModule } from './app/components/footer-navbar/footer-navbar.module';
import { HeaderModule } from './app/components/header/header.module';
import { InputModule } from 'src/app/components/input/input.module';
import { MainButtonModule } from './app/components/main-button/main-button.module';
import { ModalModule } from './app/components/modal/modal.module';
import { PostComponentModule } from './app/components/post/Post.module';
import { LoadingSpinnerModule } from './app/components/loading-spinner/loading-spinner.module';
import { WelcomeModalModule } from './app/components/welcome-modal/welcome-modal.module';
import { DownloadAppComponentModule } from './app/components/download-app/footer-navbar.module';
import { CollectionButtonModule } from './app/components/collection-button/collection-button.module';

@NgModule({
  exports: [
    AnnouncementModule,
    AreasOfInterestModule,
    CarrouselModule,
    CommonModule,
    CollectionButtonModule,
    CustomPipesModule,
    DownloadAppComponentModule,
    FooterNavbarModule,
    FormsModule,
    HeaderModule,
    InputModule,
    IonicModule,
    LoadingSpinnerModule,
    MainButtonModule,
    ModalModule,
    PostComponentModule,
    ReactiveFormsModule,
    WelcomeModalModule,
  ]
})
export class QveryCommonModule { }
