import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { CardData } from '../pages/profile/configuration/payments/payments.page';
import { PaymentIntentRequest } from '@blackfor-mexico/qvery-dto-interfaces';
import { MASTER_SERVER_ENDPOINTS, PAYMENT_SERVICE_CONFIG } from '../utils/constants';
import { StorageService } from './storage.service';

interface SetupIntentCreationResponse {
  clientSecret: string;
}

const serverBaseUrl = MASTER_SERVER_ENDPOINTS.serverBaseUrl;
const baseApiPath = MASTER_SERVER_ENDPOINTS.basePath;

@Injectable({
  providedIn: 'root'
})
export class PaymentsService {
  private stripe?: Stripe;

  constructor(
    private http: HttpClient,
    private storageService: StorageService,
  ) { }

  public async loadStripe(): Promise<Stripe> {
    if (!this.stripe) {
      this.stripe = await loadStripe(PAYMENT_SERVICE_CONFIG.publishableKey);
    }
    return this.stripe;
  }

  public async createSetupIntent() {
    try {
      const endpoint = MASTER_SERVER_ENDPOINTS.payment.setupIntent;
      const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}`;

      /* eslint-disable @typescript-eslint/naming-convention */
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${await this.storageService.getJwt()}`,
        }),
        observe: 'response' as const,
      };
      /* eslint-enable @typescript-eslint/naming-convention */

      console.log(`[paymentService][createSetupIntent] Querying to ${queryUrl}...`);
      const response = await this.http.post<SetupIntentCreationResponse>(queryUrl, {}, httpOptions).toPromise();
      console.log(`[paymentService][createSetupIntent] Response from server: ${response.status}`, response);
      return response;
    } catch (e) {
      console.error('[paymentService][createSetupIntent] Error creating setupIntent', e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }

  // public async createSetupIntentOxxo(price: string) {
  //   try {
  //     const endpoint = MASTER_SERVER_ENDPOINTS.payment.setupIntentOxxo;
  //     const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}`;

  //     /* eslint-disable @typescript-eslint/naming-convention */
  //     const httpOptions = {
  //       headers: new HttpHeaders({
  //         'Content-Type': 'application/json',
  //         Authorization: `Bearer ${await this.storageService.getJwt()}`,
  //       }),
  //       observe: 'response' as const,
  //     };
  //     /* eslint-enable @typescript-eslint/naming-convention */
  //     const body = {price};
  //     console.log(`[paymentService][createSetupIntentOxxo] Querying to ${queryUrl}...`);
  //     const response = await this.http.post<SetupIntentCreationResponse>(queryUrl, body, httpOptions).toPromise();
  //     console.log(`[paymentService][createSetupIntentOxxo] Response from server: ${response.status}`, response);
  //     return response;
  //   } catch (e) {
  //     console.error('[paymentService][createSetupIntentOxxo] Error creating setupIntent in Oxxo', e);
  //     throw new Error((e as HttpErrorResponse).message);
  //   }
  // }

  public async createPaymentIntent(paymentDetails: PaymentIntentRequest) {
    try {
      const endpoint = MASTER_SERVER_ENDPOINTS.payment.paymentIntent;
      const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}`;

      /* eslint-disable @typescript-eslint/naming-convention */
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${await this.storageService.getJwt()}`,
        }),
        observe: 'response' as const,
      };
      /* eslint-enable @typescript-eslint/naming-convention */

      console.log(`[paymentService][createPaymentIntent] Querying to "${queryUrl}" with body:`, paymentDetails);
      const response = await this.http.post(queryUrl, paymentDetails, httpOptions).toPromise();
      console.log(`[paymentService][createPaymentIntent] Response from server: ${response.status}`, response);
      return response;
    } catch (e) {
      console.error('[paymentService][createPaymentIntent] Error creating paymentIntent', e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }

  public async createPaymentIos(paymentDetails) {
    try {
      const endpoint = MASTER_SERVER_ENDPOINTS.payment.paymentIos;
      const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}`;

      /* eslint-disable @typescript-eslint/naming-convention */
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${await this.storageService.getJwt()}`,
        }),
        observe: 'response' as const,
      };
      /* eslint-enable @typescript-eslint/naming-convention */

      const response = await this.http.post(queryUrl, paymentDetails, httpOptions).toPromise();
      console.log(`[paymentService][createPaymentIos] Response from server: ${response.status}`, response);
      return response;
    } catch (e) {
      console.error('[paymentService][createPaymentIos] Error creating paymentIntent', e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }

  public async getPaymentMethods() {
    try {
      const endpoint = MASTER_SERVER_ENDPOINTS.payment.getPaymentMethods;
      const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}`;

      /* eslint-disable @typescript-eslint/naming-convention */
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${await this.storageService.getJwt()}`,
        }),
        observe: 'response' as const,
      };
      /* eslint-enable @typescript-eslint/naming-convention */

      console.log(`[paymentService][getPaymentMethods] Querying to ${queryUrl}...`);
      const response = await this.http.get<CardData[]>(queryUrl, httpOptions).toPromise();
      console.log(`[paymentService][getPaymentMethods] Response from server: ${response.status}`, response);
      return response;
    } catch (e) {
      console.error('[paymentService][getPaymentMethods] Error getting payment methods', e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }

  public async setDefaultPaymentMethod(paymentMethodId: string) {
    try {
      const endpoint = MASTER_SERVER_ENDPOINTS.payment.setDefaultPaymentMethod;
      // The endpoint receives the paymentMethodId as a path param
      const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}/${paymentMethodId}`;

      /* eslint-disable @typescript-eslint/naming-convention */
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${await this.storageService.getJwt()}`,
        }),
        observe: 'response' as const,
      };
      /* eslint-enable @typescript-eslint/naming-convention */

      console.log(`[paymentService][setDefaultPaymentMethod] Querying to ${queryUrl}...`);
      const response = await this.http.put(queryUrl, {}, httpOptions).toPromise();
      console.log(`[paymentService][setDefaultPaymentMethod] Response from server: ${response.status}`, response);
      return response;
    } catch (e) {
      console.error('[paymentService][setDefaultPaymentMethod] Error setting default payment method', e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }

  public async deletePaymentMethod(paymentMethodId: string) {
    try {
      const endpoint = MASTER_SERVER_ENDPOINTS.payment.deletePaymentMethod;
      // The endpoint receives the paymentMethodId as a path param
      const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}/${paymentMethodId}`;

      /* eslint-disable @typescript-eslint/naming-convention */
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${await this.storageService.getJwt()}`,
        }),
        observe: 'response' as const,
      };
      /* eslint-enable @typescript-eslint/naming-convention */

      console.log(`[paymentService][deletePaymentMethod] Querying to ${queryUrl}...`);
      const response = await this.http.delete(queryUrl, httpOptions).toPromise();
      console.log(`[paymentService][deletePaymentMethod] Response from server: ${response.status}`, response);
      return response;
    } catch (e) {
      console.error('[paymentService][deletePaymentMethod] Error deleting payment method', e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }

  public async getPaidContentPrice(paidContentTypeId: number, creatorId: number) {
    try {
      const endpoint = MASTER_SERVER_ENDPOINTS.payment.getPaidContentPrice;
      const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}`;

      /* eslint-disable @typescript-eslint/naming-convention */
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${await this.storageService.getJwt()}`,
        }),
        params: {
          creatorId,
          paidContentTypeId,
        },
        observe: 'response' as const,
      };
      /* eslint-enable @typescript-eslint/naming-convention */

      console.log(`[paymentService][getPaidContentPrice] Querying to ${queryUrl}...`);
      const response = await this.http.get<{ price: number }>(queryUrl, httpOptions).toPromise();
      console.log(`[paymentService][getPaidContentPrice] Response from server: ${response.status}`, response);
      return response;
    } catch (e) {
      console.error('[paymentService][getPaidContentPrice] Error getting paidContentPrice:', e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }

  public async createSubscription(creatorId: number) {
    try {
      const endpoint = MASTER_SERVER_ENDPOINTS.payment.createSubscription;
      const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}`;

      /* eslint-disable @typescript-eslint/naming-convention */
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${await this.storageService.getJwt()}`,
        }),
        observe: 'response' as const,
      };
      /* eslint-enable @typescript-eslint/naming-convention */

      console.log(`[paymentService][createSubscription] Querying to ${queryUrl}...`);
      const response = await this.http.post(queryUrl, { creatorId }, httpOptions).toPromise();
      console.log(`[paymentService][createSubscription] Response from server: ${response.status}`, response);
      return response;
    } catch (e) {
      console.error('[paymentService][createSubscription] Error creating subscription', e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }

  public async cancelSubscription(creatorId: number) {
    try {
      const endpoint = MASTER_SERVER_ENDPOINTS.payment.cancelSubscription;
      const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}` + '/' + creatorId;

      /* eslint-disable @typescript-eslint/naming-convention */
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${await this.storageService.getJwt()}`,
        }),
        observe: 'response' as const,
      };
      /* eslint-enable @typescript-eslint/naming-convention */

      console.log(`[paymentService][deleteSubscription] Querying to ${queryUrl}...`);
      const response = await this.http.delete(queryUrl, httpOptions).toPromise();
      console.log(`[paymentService][deleteSubscription] Response from server: ${response.status}`, response);
      return response;
    } catch (e) {
      console.error('[paymentService][deleteSubscription] Error creating subscription', e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }

  public async createSubscriptionIos(appleResponse: any) {
    try {
      const endpoint = MASTER_SERVER_ENDPOINTS.payment.createSubscriptionIos;
      const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}`;

      /* eslint-disable @typescript-eslint/naming-convention */
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${await this.storageService.getJwt()}`,
        }),
        observe: 'response' as const,
      };
      /* eslint-enable @typescript-eslint/naming-convention */

      console.log(`[paymentService][createSubscriptionIos] Querying to ${queryUrl}...`);
      const response = await this.http.post(queryUrl, appleResponse, httpOptions).toPromise();
      console.log(`[paymentService][createSubscriptionIos] Response from server: ${response.status}`, response);
      return response;
    } catch (e) {
      console.error('[paymentService][createSubscription] Error creating subscription', e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }

  public async updateExpiricySubscription(params) {
    try {
      const endpoint = MASTER_SERVER_ENDPOINTS.payment.updateExpiricySubscription;
      const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}`;

      /* eslint-disable @typescript-eslint/naming-convention */
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${await this.storageService.getJwt()}`,
        }),
        observe: 'response' as const,
      };
      /* eslint-enable @typescript-eslint/naming-convention */

      console.log(`[paymentService][updateExpiricySubscription] Querying to ${queryUrl}...`);
      const response = await this.http.post(queryUrl, params, httpOptions).toPromise();
      console.log(`[paymentService][updateExpiricySubscription] Response from server: ${response.status}`, response);
      return response;
    } catch (e) {
      console.error('[paymentService][updateExpiricySubscription] Error updating expiricy', e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }

  // public async createPaymentIntentOxxo(paymentDetails: any) {
  //   try {
  //     const endpoint = MASTER_SERVER_ENDPOINTS.payment.createPaymentIntentOxxo;
  //     const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}`;

  //     /* eslint-disable @typescript-eslint/naming-convention */
  //     const httpOptions = {
  //       headers: new HttpHeaders({
  //         'Content-Type': 'application/json',
  //         Authorization: `Bearer ${await this.storageService.getJwt()}`,
  //       }),
  //       observe: 'response' as const,
  //     };
  //     /* eslint-enable @typescript-eslint/naming-convention */

  //     console.log(`[paymentService][createPaymentIntentOxxo] Querying to "${queryUrl}" with body:`, paymentDetails);
  //     const response = await this.http.post(queryUrl, paymentDetails, httpOptions).toPromise();
  //     console.log(`[paymentService][createPaymentIntentOxxo] Response from server: ${response.status}`, response);
  //     return response;
  //   } catch (e) {
  //     console.error('[paymentService][createPaymentIntentOxxo] Error creating paymentIntent', e);
  //     throw new Error((e as HttpErrorResponse).message);
  //   }
  // }

  // public async updatePaymentIntentOxxo(paymentDetails: any) {
  //   try {
  //     const endpoint = MASTER_SERVER_ENDPOINTS.payment.updatePaymentIntentOxxo;
  //     const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}`;

  //     /* eslint-disable @typescript-eslint/naming-convention */
  //     const httpOptions = {
  //       headers: new HttpHeaders({
  //         'Content-Type': 'application/json',
  //         Authorization: `Bearer ${await this.storageService.getJwt()}`,
  //       }),
  //       observe: 'response' as const,
  //     };

  //     const response = await this.http.put(queryUrl, paymentDetails, httpOptions).toPromise();
  //     console.log(`[paymentService][updatePaymentIntentOxxo] Response from server: ${response.status}`, response);
  //     return response;
  //   } catch (e) {
  //     console.error('[paymentService][updatePaymentIntentOxxo] Error creating paymentIntent', e);
  //     throw new Error((e as HttpErrorResponse).message);
  //   }
  // }

  public async createIntentForMissingGreeting(paymentDetails) {
    try {
      const endpoint = MASTER_SERVER_ENDPOINTS.payment.createIntentForMissingGreeting;
      const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}`;

      /* eslint-disable @typescript-eslint/naming-convention */
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${await this.storageService.getJwt()}`,
        }),
        observe: 'response' as const,
      };
      /* eslint-enable @typescript-eslint/naming-convention */

      console.log(`[paymentService][createIntentForMissingGreeting] Querying to "${queryUrl}" with body:`, paymentDetails);
      const response = await this.http.post(queryUrl, paymentDetails, httpOptions).toPromise();
      console.log(`[paymentService][createIntentForMissingGreeting] Response from server: ${response.status}`, response);
      return response;
    } catch (e) {
      console.error('[paymentService][createIntentForMissingGreeting] Error creating IntentForMissingGreeting', e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }

  public async createRefundIntentForMissingGreeting(paymentDetails) {
    try {
      const endpoint = MASTER_SERVER_ENDPOINTS.payment.createRefundIntentForMissingGreeting;
      const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}`;

      /* eslint-disable @typescript-eslint/naming-convention */
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${await this.storageService.getJwt()}`,
        }),
        observe: 'response' as const,
      };
      /* eslint-enable @typescript-eslint/naming-convention */

      console.log(`[paymentService][createRefundIntentForMissingGreeting] Querying to "${queryUrl}" with body:`, paymentDetails);
      const response = await this.http.post(queryUrl, paymentDetails, httpOptions).toPromise();
      console.log(`[paymentService][createRefundIntentForMissingGreeting] Response from server: ${response.status}`, response);
      return response;
    } catch (e) {
      console.error('[paymentService][createRefundIntentForMissingGreeting] Error creating IntentForMissingGreeting', e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }
}
