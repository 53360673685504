import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { StorageService } from 'src/app/services/storage.service';
import { QveryJwtStructure } from '@blackfor-mexico/qvery-dto-interfaces';
import 'dayjs/locale/es-mx'; // load on demand
import { ThemeService } from '../../utils/theme.service';

export interface UserCollectionsResponse {
  createdAt: string;
  description: string;
  userId: number;
  name: string;
  postIsInCollection: boolean;
  collectionId: number;
  hasPublication?: boolean;
}

@Component({
  selector: 'collection-button',
  templateUrl: './collection-button.component.html',
  styleUrls: ['./collection-button.component.scss'],
})
export class CollectionButtonComponent implements OnInit {

  @Input() idPost;
  @Input() hasSavedInAnyCollection;
  @ViewChild('reactionBtn') reactionBtn: ElementRef;

  jwt: QveryJwtStructure;
  userCollections: UserCollectionsResponse[] = [];
  publicationInCollections: any[] = [];
  collectionSelected: number;
  newCollectionName = '';
  isCollectionAdded = true;
  isPublicationAdded = false;
  anyCollectionSelected = false;
  isTextAreaEmpty = true;

  isRequestSent = false;
  isWritingDescriptionReport = false;

  constructor(
    private userService: UserService,
    private storage: StorageService,
    public theme: ThemeService
  ) { }

  async ngOnInit() {

    this.jwt = await this.storage.getJwtAsJson();
    this.setSelectionFalse();
  }


  onClick(check) {
    this.collectionSelected = check.collectionId;
    if (check.postIsInCollection) {
      this.addToCollection();
    } else {

      this.removeFromCollectionFeed();
    }
  }

  async getUserCollections() {
    this.userCollections = await this.userService.getCollectionsByPublicationId(this.idPost);
  }

  async createCollection() {
    if (this.newCollectionName) {
      await this.userService.createCollection(this.jwt.uid, this.newCollectionName);
      await this.getUserCollections();
    }
  }

  async addToCollection() {
    const collectionId = this.collectionSelected;
    await this.userService.addToCollection(this.jwt.uid, collectionId, this.idPost);
    ++this.hasSavedInAnyCollection;
  }

  async removeFromCollectionFeed() {
    const collectionId = this.collectionSelected;
    await this.userService.removeFromCollectionFeed(collectionId, this.idPost);
    --this.hasSavedInAnyCollection;
  }

  setSelectionFalse() {
    this.userCollections.forEach(element => {
      element.postIsInCollection = false;
    })
  }

  radioGroupChange(event) {
    this.userCollections.forEach(element => {
      element.postIsInCollection = false;
      if (event.target.value === element.name) {
        element.postIsInCollection = true;
        this.anyCollectionSelected = true;
      }
    });
  }

  getLastCollectionId() {
    let collectionId: number;
    return collectionId;
  }

  newNameEmpty() {
    if (this.newCollectionName !== '') {
      this.isTextAreaEmpty = false;
    } else {
      this.isTextAreaEmpty = true;
    }
  }

  resetNewCollection() {
    this.anyCollectionSelected = false;
    this.newCollectionName = '';
  }

}
