import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit {
  @Input() showModal: boolean;
  @Input() noMargin: boolean;
  @Input() hideBtnClose?: boolean;
  @Input() blackBackground?: boolean;
  @Input() isMedia?: boolean;

  @Output() closeModal = new EventEmitter<any>();
  constructor() { }

  ngOnInit() { }

  handleCloseModal() {
    this.closeModal.emit();
  }
}
