import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { QveryJwtStructure } from '@qhot-mx/qhot-database-dto-interfaces';
import { ToastController } from '@ionic/angular';
import * as dayjs from 'dayjs';
import 'dayjs/locale/es-mx'; // load on demand
import * as utc from 'dayjs/plugin/utc';
import { AnnouncementContent } from 'src/app/pages/profile/content-feed/content-feed.page';
import { PublicationService } from 'src/app/services/publication.service';
import { StorageService } from 'src/app/services/storage.service';
import { UserService } from 'src/app/services/user.service';

dayjs.extend(utc);
dayjs.locale('es-mx');


@Component({
  selector: 'app-announcement',
  templateUrl: './announcement.component.html',
  styleUrls: ['./announcement.component.scss'],
})
export class AnnouncementComponent implements OnInit {

  @Input() announcement;
  @Input() post;
  @Input() adIndex: number;
  @Input() isOwnProfile: boolean;
  @Input() isInFeed: boolean;
  @Input() showAnnouncemenOptions: boolean;
  @Input() profileAnnouncementClass: string;


  @Output() setAdSelectedAndOpenModal = new EventEmitter<any>();
  @Output() editAd = new EventEmitter<any>();
  @Output() deletePublication = new EventEmitter<any>();
  @Output() openReportPublication: EventEmitter<any> = new EventEmitter();


  jwt: QveryJwtStructure;
  isMyOwnProfile : boolean; 

  objPostDeleting;
  showModalConfirmDeletePost = false;
  deleting = false;
  isSubscribed: boolean = true;

  constructor(
    private user: UserService,
    public publicationService: PublicationService,
    public toastController: ToastController,
    private storage: StorageService,
  ) { }

  async ngOnInit() { 
    this.jwt = await this.storage.getJwtAsJson();
    const creatorProfile = await this.user.getUserProfile(this.post.creatorId);
    this.isSubscribed = creatorProfile.isSubscribed;
    if(this.post.creatorId === this.jwt.uid)
    {
      this.isMyOwnProfile = true; 
    }else{
      this.isMyOwnProfile = false;
    }
  }

  parseUtcDate(dateString: string) {
    return dayjs.utc(dateString).local().format('YYYY-MM-DD HH:mm:ss');
  }

  getPublicationThumbnail(thisAd): string {
    if (this.announcement.image !== '') {
      return thisAd.image;
    }
    return 'assets/images/announcement_default.png';
  }

  async presentDeletingErrorToast(errorMessage: string, duration: number) {
    const toast = await this.toastController.create({
      message: errorMessage,
      duration,
      color: 'danger',
      cssClass: 'text-centered ',  //custom-toast-bold-text
      position: 'top',
    });
    await toast.present();
  }

  handleDeletePublication() {
    this.showModalConfirmDeletePost = true;
  }
  async onDeletePublication() {
    this.deleting = true;
    let id;
    id = this.announcement.publicationId;
    try {
     
      await this.publicationService.deletePublication(id);
      let messageToast = "";
      if(localStorage.getItem('language')==="es"){
        messageToast = "Post Eliminado";
      }else if(localStorage.getItem('language')==="en"){
        messageToast = "Post deleted";
      }
      let toast = await this.toastController.create({
        message: messageToast,
        duration: 2000,
        color: 'primary',
        cssClass: 'text-centered'
      });
      await toast.present();

    }
    catch (e) {
      console.error(e);
      if(localStorage.getItem('language')==="es"){
        this.presentDeletingErrorToast('Ocurrió un error eliminando tu post', 2000);
      }else if(localStorage.getItem('language')==="en"){
        this.presentDeletingErrorToast('There was a problem while deleting this post', 2000);
      }
     
    }
    finally {
      this.objPostDeleting = undefined;
      this.deleting = false;
      this.showModalConfirmDeletePost = false;
    }
  }


  showBlur() {
    if (this.isSubscribed || this.isMyOwnProfile) {
      return false;
    }
    return true;
  }
}
