import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './utils/route-guards/AuthGuard';
import { TranslateModule, TranslateService, TranslatePipe, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
export const loginAndUserRegisterRoutes = [
  '/welcome', '/login', '/register', '/terms',
];

export const shareProfileRoute = /^\/profile\/content-feed\/[0-9]+$/;
export const sharePublicationRoute = /^\/feed\/publication\/[0-9]+$/;

const routes: Routes = [
  {
    path: '',
    redirectTo: '/feed', // The app will try to go to the /feed page first
    pathMatch: 'full'
  },
  {
    path: 'welcome',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/welcome/welcome.module').then(m => m.WelcomePageModule)
  },
  {
    path: 'login',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'register',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterPageModule)
  },
  {
    path: 'terms',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/register/terms-conditions/terms-conditions.module').then( m => m.TermsConditionsPageModule)
  },
  {
    path: 'feed',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/feed/feed.module').then( m => m.FeedPageModule)
  },
  {
    path: 'new-post',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/feed/new-post/new-post.module').then( m => m.NewPostPageModule)
  },
  {
    path: 'notifications',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/notifications/notifications.module').then( m => m.NotificationsPageModule)
  },
  {
    path: 'notifications/hello',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/hello/hello.module').then( m => m.HelloPageModule)
  },
  {
    path: 'notifications/hello-request',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/hello-request/hello-request.module').then( m => m.HelloRequestPageModule)
  },
  {
    path: 'profile',
    // canActivate: [AuthGuard],
    loadChildren: () => import('./pages/profile/profile.module').then( m => m.ProfilePageModule)
  },
  {
    path: 'profile/edit',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/profile/edit-profile/edit-profile.module').then( m => m.EditProfilePageModule)
  },
  {
    path: 'profile/statistics',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/profile/statistics/statistics.module').then( m => m.StatisticsPagePageModule)
  },
  {
    path: 'terms-and-conditions',
    loadChildren: () => import('./pages/welcome/terms-and-conditions/terms-and-conditions.module').then( m => m.TermsAndConditionsPageModule)
  },
  {
    path: 'privacy',
    loadChildren: () => import('./pages/welcome/privacy-policy/privacy-policy.module').then( m => m.PrivacyPolicyPageModule)
  },
  {
    path: 'support',
    loadChildren: () => import('./pages/support-page/support-page.module').then( m => m.SupportPagePageModule)
  },
  {
    path: 'profile-not-found',
    loadChildren: () => import('./pages/profile-not-found/profile-not-found.module').then( m => m.ProfileNotFoundPageModule)
  },
  {
    path: 'stripe-register',
    loadChildren: () => import('./pages/feed/stripe-register/stripe-register.module').then( m => m.StripeRegisterPageModule)
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
    TranslateModule
  ],
  providers: [TranslateModule,AuthGuard],
  exports: [TranslatePipe,RouterModule]
})
export class AppRoutingModule { }
