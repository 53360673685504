import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MASTER_SERVER_ENDPOINTS } from '../utils/constants';
import { StorageService } from './storage.service';


const serverBaseUrl = MASTER_SERVER_ENDPOINTS.serverBaseUrl;
const baseApiPath = MASTER_SERVER_ENDPOINTS.basePath;

@Injectable({
  providedIn: 'root'
})
export class CommentsService {

  constructor(
    private http: HttpClient,
    private storageService: StorageService,
  ) { }

  public async createCommentResponse(params) {
    const endpoint = MASTER_SERVER_ENDPOINTS.comments.createComment;
    const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}`;

    const body = JSON.stringify(params);
    /* eslint-disable @typescript-eslint/naming-convention */
    const httpOptions = {
      headers: new HttpHeaders({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type':  'application/json',
        Authorization: `Bearer ${await this.storageService.getJwt()}`,
      })
    };

    try {
      const response = await this.http.post(queryUrl, body, httpOptions).toPromise();
      return response;
    } catch (e) {
      console.error('[comments-service][createComment] Error while creating comment', e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }


  public async deleteCommentComment(form) { //TODO
    const endpoint = MASTER_SERVER_ENDPOINTS.comments.deleteComment;
    const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}`;

    const body = JSON.stringify(form);
    /* eslint-disable @typescript-eslint/naming-convention */
    const httpOptions = {
      headers: new HttpHeaders({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type':  'application/json',
        Authorization: `Bearer ${await this.storageService.getJwt()}`,
      }),
      body
    };

    try {
      const response = await this.http.delete(queryUrl, httpOptions).toPromise();
      return response;
    } catch (e) {
      console.error('[comments-service][deleteComment] Error while deleting comment', e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }

  public async createCommentsReaction(form) {
    const endpoint = MASTER_SERVER_ENDPOINTS.comments.createReaction;
    const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}`;

    /* eslint-disable @typescript-eslint/naming-convention */
    const httpOptions = {
      headers: new HttpHeaders({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type':  'application/json',
        Authorization: `Bearer ${await this.storageService.getJwt()}`,
      }),
      observe: 'response' as const,
    };

    const body = JSON.stringify(form);
    try {
      console.log(`[comments-service][createCommentsReaction] Querying to ${queryUrl}... with body ${body}`);
      const response = await this.http.post(queryUrl, body, httpOptions).toPromise();
      console.log(`[comments-service][createCommentsReaction] Response from server: ${response.status} ${response.statusText}`);
      return response;
    } catch (e) {
      console.error('[comments-service][createCommentsReaction] Error while creating reaction', e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }

  public async deleteCommentsReaction(form) {
    const endpoint = MASTER_SERVER_ENDPOINTS.comments.deleteReaction;
    const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}`;

    const body = JSON.stringify(form);
    /* eslint-disable @typescript-eslint/naming-convention */
    const httpOptions = {
      headers: new HttpHeaders({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type':  'application/json',
        Authorization: `Bearer ${await this.storageService.getJwt()}`,
      }),
      body
    };

    try {
      const response = await this.http.delete(queryUrl, httpOptions).toPromise();
      return response;
    } catch (e) {
      console.error('[comments-service][deleteCommentsReaction] Error while dleting reaction', e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }

  public async reportComment(params) {
    const endpoint = MASTER_SERVER_ENDPOINTS.comments.reportComment;
    const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}`;

    const body = JSON.stringify(params);
    /* eslint-disable @typescript-eslint/naming-convention */
    const httpOptions = {
      headers: new HttpHeaders({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type':  'application/json',
        Authorization: `Bearer ${await this.storageService.getJwt()}`,
      })
    };

    try {
      const response = await this.http.post(queryUrl, body, httpOptions).toPromise();
      return response;
    } catch (e) {
      console.error('[comments-service][reportComment] Error while reporting comment', e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }

  public async blockUserComments(params) {
    const endpoint = MASTER_SERVER_ENDPOINTS.comments.blockUserComments;
    const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}`;

    const body = JSON.stringify(params);
    /* eslint-disable @typescript-eslint/naming-convention */
    const httpOptions = {
      headers: new HttpHeaders({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type':  'application/json',
        Authorization: `Bearer ${await this.storageService.getJwt()}`,
      })
    };

    try {
      const response = await this.http.post(queryUrl, body, httpOptions).toPromise();
      return response;
    } catch (e) {
      console.error('[comments-service][blockUserComments] Error while blocking user comments', e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }

  public async getCommentUserBlocked() {
    const endpoint = MASTER_SERVER_ENDPOINTS.comments.blockUserComments;
    const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}`;

    /* eslint-disable @typescript-eslint/naming-convention */
    const httpOptions = {
      headers: new HttpHeaders({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type':  'application/json',
        Authorization: `Bearer ${await this.storageService.getJwt()}`,
      })
    };

    try {
      const response = await this.http.get(queryUrl, httpOptions).toPromise();
      return response;
    } catch (e) {
      console.error('[comments-service][getCommentUserBlocked] Error while getting blocked user comments', e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }
}
