import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MASTER_SERVER_ENDPOINTS } from '../utils/constants';
import { StorageService } from './storage.service';
import { Notification } from '@qhot-mx/qhot-database-dto-interfaces';
const serverBaseUrl = MASTER_SERVER_ENDPOINTS.serverBaseUrl;
const baseApiPath = MASTER_SERVER_ENDPOINTS.basePath;


export interface NotificationsCount {
  count: number;
}

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  constructor(
    private http: HttpClient,
    private storageService: StorageService,
  ) { }

  public async getUserNotifications(lastId?, filters?): Promise<any[]> {
    const endpoint = MASTER_SERVER_ENDPOINTS.notifications.getNotifications;
    const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}`;
    /* eslint-disable @typescript-eslint/naming-convention */
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.storageService.getJwt()}`,
      }),
      params: new HttpParams()
        .append('lastNotificationId', lastId ? lastId : null)
        .append('pageSize', 10)
        .append('filters', filters.toString()),
    };
    /* eslint-enable @typescript-eslint/naming-convention */

    try {
      console.log(`[getUserNotifications] Querying to "${queryUrl}"...`);
      const userNotifications = await this.http.get<any[]>(queryUrl, httpOptions).toPromise();
      return userNotifications;
    } catch (e) {
      console.error(`[getUserNotifications] Error while getting feed posts`, e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }

  public async getNotificationsCount(): Promise<NotificationsCount> {
    const endpoint = MASTER_SERVER_ENDPOINTS.notifications.getNotificationCount;
    const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}`;

    /* eslint-disable @typescript-eslint/naming-convention */
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.storageService.getJwt()}`,
      }),
    };
    /* eslint-enable @typescript-eslint/naming-convention */

    try {
      console.log(`[getNotificationsCount] Querying to "${queryUrl}"...`);
      const userNotifications = await this.http.get<NotificationsCount>(queryUrl, httpOptions).toPromise();
      return userNotifications;
    } catch (e) {
      console.error(`[getNotificationsCount] Error while getting notifications count`, e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }

  public async toggleNotification(notificationId) {
    const endpoint = MASTER_SERVER_ENDPOINTS.notifications.toggle;
    const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}`;

    /* eslint-disable @typescript-eslint/naming-convention */
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.storageService.getJwt()}`,
      }),
    };
    const body = JSON.stringify({
      notificationId,
    });
    /* eslint-enable @typescript-eslint/naming-convention */

    try {
      console.log(`[toggleNotification] Querying to "${queryUrl}"...`);
      await this.http.put(queryUrl, body, httpOptions).toPromise();
      await this.storageService.remove('notificationsCount');
      this.getNotificationsCount();
    } catch (e) {
      console.error(`[toggleNotification] Error while getting feed posts`, e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }

    public async toggleAllNotifications() {
    const endpoint = MASTER_SERVER_ENDPOINTS.notifications.toggleAll;
    const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}`;

    /* eslint-disable @typescript-eslint/naming-convention */
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.storageService.getJwt()}`,
      }),
    };

    /* eslint-enable @typescript-eslint/naming-convention */

    try {
      console.log(`[toggleNotification] Querying to "${queryUrl}"...`);
      await this.http.get(queryUrl, httpOptions).toPromise();
      await this.storageService.remove('notificationsCount');
      this.getNotificationsCount();
    } catch (e) {
      console.error(`[toggleNotification] Error while getting feed posts`, e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }

  public async getNotificationCatalog(userId: number) {
    const endpoint = MASTER_SERVER_ENDPOINTS.notifications.notificationType;
    const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}`;

    /* eslint-disable @typescript-eslint/naming-convention */
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.storageService.getJwt()}`,
      })
    };
    /* eslint-enable @typescript-eslint/naming-convention */

    try {
      console.log(`[user-service][getUserProfile] Querying to "${queryUrl}"...`);
      const userProfile = await this.http.get(queryUrl, httpOptions).toPromise();
      return userProfile;
    } catch (e) {
      console.error('[user-service][getUserProfile] Error while getting creator', e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }
}
