import { PostComponent } from './post.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CustomPipesModule } from 'src/app/utils/custom-pipes/custom-pipes.module';
import { FileTransfer } from '@awesome-cordova-plugins/file-transfer/ngx';
import { PhotoLibrary } from '@awesome-cordova-plugins/photo-library/ngx';
import { CollectionButtonModule } from '../collection-button/collection-button.module';
import { FormsModule } from '@angular/forms';
import { ModalModule } from 'src/app/components/modal/modal.module';
import { MainButtonModule } from 'src/app/components/main-button/main-button.module';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
    declarations: [
        PostComponent,
    ],
    exports: [
        PostComponent,
    ],
    imports: [
        CommonModule,
        TranslateModule,
        CustomPipesModule,
        IonicModule,
        CollectionButtonModule,
        FormsModule,
        ModalModule,
        MainButtonModule,
    ],
    providers: [FileTransfer, PhotoLibrary],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})

export class PostComponentModule { }
