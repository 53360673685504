import { Component, Input, ViewChild, OnInit, ElementRef, SimpleChanges, AfterViewInit, OnChanges } from '@angular/core';
import { UserProfile } from '../../profile/profile.page';
import { CreatorSearchService } from 'src/app/services/creator-search.service';
import Swiper from 'swiper';

@Component({
  selector: 'app-carrousel',
  templateUrl: './carrousel.component.html',
  styleUrls: ['./carrousel.component.scss'],
})
export class CarrouselComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild('carousel') swiperRef: ElementRef<HTMLElement & { swiper?: Swiper } & { initialize: () => void }> | undefined;
  @Input() titleCarrousel: string;
  @Input() content: UserProfile[];
  @Input() largerTitle?: boolean;
  @Input() lastId?: number;
  @Input() isRecentCarousel?: boolean;
  @Input() addClass?: string;
  @Input() seeAll?: boolean = false;

  platform = '';
  canRequest = true;
  pagesPerView =3.5;
  swiper?: Swiper;

  constructor(
    private creatorSearch: CreatorSearchService,
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    const swiperEl = Object.assign(this.swiperRef.nativeElement, {
      slidesPerView: this.pagesPerView,
      centeredSlides: true,
      centeredSlidesBounds: true,
      spaceBetween: 12,
    });

    this.swiper = this.swiperRef.nativeElement.swiper;

    this.swiper.on('reachEnd', async (ev) => {
      await this.moreData();
    });

    this.swiper.on('transitionEnd', () => {
      this.swiper.updateSlides();
    })
    swiperEl.initialize();
  }

  ngOnChanges(changes: SimpleChanges) {
    // eslint-disable-next-line @typescript-eslint/dot-notation
    const currentVal = changes['content']?.currentValue;
    if ((currentVal !== changes['content']?.previousValue) && !changes['content']?.firstChange && currentVal?.length > 2) {
      setTimeout(() => {
        this.swiper.updateSlides();
      }, 500);
    }
  }

  async fetchCarrouselData(lastId?) {

    await this.creatorSearch.getRecentCreators(5, lastId ? lastId : null).then((res: any) => {
      if (!this.content.length) {
        this.content = res;
      } else {
        const newCreators = res;
        newCreators.forEach(element => {
          this.content = [...this.content, element];
        });
      }
      try {
        if (res.length === 5) {
          this.lastId = res[res.length - 1].userId;
        } else {
          this.lastId = res[res.length - 1].userId;
          this.canRequest = false;
        }
      }
      catch {
        this.canRequest = false;
      }
    });
  }

  moreData() {
    if(this.isRecentCarousel){
    if (this.content) {
      if (this.canRequest) {
        this.fetchCarrouselData(this.lastId);
      }
    }
  }

}
}
